/**
 * @title Seafood link
 * @id components/seafood-link
 * @markup ../../styleguide/seafood-link.html
 */
.seafood-link {
  margin: var(--gutter) calc(-1 * var(--half-gutter));
}

.seafood-link__link {
  display: block;
  border-bottom: 0;
  padding: var(--half-gutter);
}

.seafood-link__heading {
  margin-top: 0;
}

.seafood-link__figure {
  margin: var(--gutter) 0 0;
}

.seafood-link__image {
  vertical-align: top;
}

@media (--small-up) {
  .seafood-link__figure {
    margin-top: 0;
  }
}
