/**
 * @title Footer
 * @id components/footer
 * @markup ../../styleguide/footer.html
 * @width fullwidth
 */
.footer {
  padding-top: var(--wide-gutter);
  background: var(--color-beige);
  clear: both;
}

.section + .footer {
  border-top: 1px solid var(--color-darkbeige);
}

.footer__main {
  background: var(--color-black);
  color: var(--color-white);
  padding-top: var(--wide-gutter);
  padding-bottom: var(--gutter);
}

.footer__main *:focus {
  outline: 2px solid var(--color-white);
}

.footer__main a:hover,
.footer__main a:focus {
  background-color: var(--color-textblack);
}

.footer__address,
.footer__address-lines {
  margin-bottom: var(--gutter);
}

.footer__address:last-child,
.footer__about-text:last-child {
  margin-bottom: var(--wide-gutter);
}

.footer__name {
  font-size: 2rem;
  line-height: 1.25;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: var(--font-wwf);
}

.footer__address-lines,
.footer__email,
.footer__phone {
  font-size: 1.3rem;
  line-height: 1.25;
}

.footer__email,
.footer__phone,
.footer__about-text p {
  margin-bottom: 0;
}

.footer__social {
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.25;
  margin: var(--gutter) 0 var(--wide-gutter);
}

.footer__social > li {
  margin-bottom: var(--gutter);
}

.footer__facebook {
  padding-left: 3rem;
  background: inline("images/facebook-white.svg") 0.3rem center / 0.9rem auto no-repeat;
}

.footer__instagram {
  padding-left: 3rem;
  background: inline("images/instagram-white.svg") left center / 1.5rem auto no-repeat;
}

.footer__twitter {
  padding-left: 3rem;
  background: inline("images/twitter-white.svg") left center / 1.5rem auto no-repeat;
}

.footer__youtube {
  padding-left: 3rem;
  background: inline("images/youtube-white.svg") left center / 1.5rem auto no-repeat;
}

.footer__sitemap-section {
  margin-bottom: var(--wide-gutter);
}

.footer__link-list > li {
  margin-bottom: 0.75rem;
}

.footer__social > li > a,
.footer__link-list > li > a {
  border-bottom-color: transparent;
}

.footer__social > li > a:hover,
.footer__social > li > a:focus,
.footer__link-list > li > a:hover,
.footer__link-list > li > a:focus {
  border-bottom-color: var(--color-white);
}

.footer__expand-list {
  color: inherit;
  background: transparent;
  padding: 0;
  border-width: 0 0 1px;
  border-color: transparent;
}

.footer__expand-list:hover,
.footer__expand-list:focus {
  background-color: var(--color-textblack);
  border-bottom-color: var(--color-white);
}

.footer__text {
  margin: var(--gutter) 0;
}

.footer__ik-figure {
  margin: 0;
}

.footer__ik-image {
  width: 100px;
  height: 105px;
}

@media (--medium-up) {
  .footer__text {
    margin: 0;
  }

  .footer__ik-figure {
    margin: 0 var(--half-gutter);
  }
}
