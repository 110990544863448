/**
 * @title Bullet
 * @id components/bullet
 * @markup ../../styleguide/bullet.html
 */

.bullet {
  display: flex;
  align-items: center;
}

.bullet:before {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 0.5em;
  border-radius: 50%;
  background-color: var(--color-red);
  animation: infinite red-pulse 1300ms ease-out;
}

.bullet--orange:before {
  background-color: var(--color-orange);
  animation-name: orange-pulse;
}

.bullet--green:before {
  background-color: var(--color-green);
  animation-name: green-pulse;
}

.bullet--redline:before {
  background-color: transparent;
  border: 2px solid var(--color-red);
  animation-name: none;
}

.bullet--grayline:before {
  background-color: transparent;
  border: 2px solid var(--color-gray);
  animation-name: none;
}
