html {
  height: 100%;
}

body {
  background-color: var(--color-white);
  color: var(--color-textblack);
  margin: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

::selection {
  background: var(--color-darkbeige);
  color: var(--color-black);
}

*:focus {
  outline: 2px solid var(--color-black);
  outline-offset: 2px;
}
