/**
 * @title Timeline
 * @id components/timeline
 * @markup ../../styleguide/timeline.html
 */

.timeline {
  margin-top: var(--gutter);
  margin-left: calc(var(--half-gutter) * -1);
  margin-bottom: var(--gutter);
  @media (--small-up) {
    margin-left: 0;
  }
}

.timeline__item {
  padding-left: var(--half-gutter);
  padding-bottom: var(--half-gutter);
  margin-left: var(--half-gutter);
  position: relative;
  border-left: 1px solid var(--color-gray);
  border-bottom: 1px solid transparent; /* Fix to avoid collapsing margins problems */
  @media (--small-up) {
    padding-left: var(--gutter);
  }
}

.timeline__item:last-child {
  border-left: none;
}

.timeline__item--current {
  padding-bottom: var(--gutter);
}

.timeline__item--current .timeline__bullet {
  background-color: var(--color-orange);
  animation: infinite orange-pulse 1300ms ease-out;
}

.timeline__bullet {
  display: inline-block;
  width: 15px;
  height: 15px;
  position: absolute;
  left: -0.5rem;
  top: 0;
  border-radius: 50%;
  background-color: var(--color-blue);
}

.timeline__bullet--milestone,
.timeline__bullet--win,
.timeline__bullet--loss {
  width: 1.5rem;
  height: 1.5rem;
  top: -0.35rem;
  left: -0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (--small-up) {
    width: 2rem;
    height: 2rem;
    top: -0.5rem;
    left: -1rem;
  }
}

.timeline__bullet--win {
  background-color: var(--color-yellow);
}

.timeline__bullet--milestone {
  background-color: var(--color-red);
}

.timeline__bullet--loss {
  background-color: var(--color-gray);
}

.timeline__bullet--milestone:before,
.timeline__bullet--win:before,
.timeline__bullet--loss:before {
  content: "";
  display: inline-block;
  width: 0.75rem;
  height: 0.75rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  @media (--small-up) {
    width: 1rem;
    height: 1rem;
  }
}

.timeline__bullet--win:before {
  background-image: inline("images/trophy.svg");
}

.timeline__bullet--milestone:before {
  background-image: inline("images/heart.svg");
}

.timeline__bullet--loss:before {
  background-image: inline("images/broken-heart.svg");
}

.timeline__meta {
  font-size: 0.666rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}
