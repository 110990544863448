/**
 * @title Color labels
 * @id components/color-labels
 * @markup ../../styleguide/color-labels.html
 */
.color-labels {
  display: flex;
}

.color-labels__item {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  background: var(--color-rockgray);
  border-radius: 50%;
  margin-right: 1rem;
}

.color-labels__item--green {
  background: var(--color-green);
}

.color-labels__item--yellow {
  background: var(--color-yellow);
}

.color-labels__item--red {
  background: var(--color-red);
}

.color-labels__text {
  position: absolute;
  width: 1px;
  height: 1px;
  clip: rect(1px, 1px, 1px, 1px);
}
