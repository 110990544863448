/**
 * @title Red list
 * @id components/red-list
 * @markup ../../styleguide/red-list.html
 */

.red-list {
  /* Top-level element has no styles */
}

.red-list__title {
  font-family: var(--font-opensans);
  font-size: 1.3rem; /* 20.8px */
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: normal;
  text-transform: none;
  margin: 0 0 2rem 0;
  text-align: center;
}

.red-list__list {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  margin-bottom: 3rem;
}

.red-list__item {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-basis: calc(100% / 7);
  width: calc(100% / 7);
}

.red-list__tab {
  font-size: 1.25rem;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: var(--font-wwf);
  border: none;
  position: relative;
  padding: 0.5rem;
  padding-bottom: 1.5rem;
  width: 100%;
  color: var(--color-gray);
  @media (--xsmall-up) {
    font-size: 1.5rem;
    padding-bottom: 2rem;
  }
  @media (--small-up) {
    padding-bottom: 2.5rem;
    font-size: 2.25rem;
  }
}

.red-list__tab:focus {
  background: none;
  outline: none;
}

.red-list__tab:focus:after {
  box-shadow:
    0 0 0 2px var(--color-black);
}

.red-list__tab:before {
  content: '';
  position: absolute;
  height: 1rem;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--color-rockgray);
  @media (--small-up) {
    height: 1.5rem;
  }
}

.red-list__tab:after {
  content: "";
  display: inline-block;
  width: 1rem;
  height: 1rem;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%,0);
  border-radius: 50%;
  background-color: var(--color-gray);
  @media (--small-up) {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.red-list__tab:hover {
  background: none;
}

.red-list__tab:hover:after {
  background-color: var(--color-red);
}

.red-list__item:first-child
.red-list__tab:before,
.red-list__tab--cap-left:before {
  left: 50%;
}

.red-list__item:last-child .red-list__tab:before,
.red-list__tab--cap-right:before {
  right: 50%;
}

.red-list__tab[aria-selected="true"],
.red-list__tab--default {
  color: var(--color-textblack);
}

.red-list__tab[aria-selected="true"]:after,
.red-list__tab--default:after {
  background-color: var(--color-red);
  width: 1.5rem;
  height: 1.5rem;
  bottom: -0.25rem;
  @media (--small-up) {
    width: 2rem;
    height: 2rem;
  }
}

.red-list__tab--grayline:before,
.red-list__tab--redline:before {
  display: none;
}

/* Outline state colors */

.red-list__tab--grayline:after {
  background-color: var(--color-white);
  border: 2px solid var(--color-gray);
}

.red-list__tab--redline:after {
  background-color: var(--color-white);
  border: 2px solid var(--color-red);
}

/* Active state colors */

.red-list__tab--grayline[aria-selected="true"]:after,
.red-list__tab--grayline.red-list__tab--default:after,
.red-list__tab--grayline:hover:after {
  background-color: var(--color-white);
  border: 4px solid var(--color-gray);
}

.red-list__tab--green[aria-selected="true"]:after,
.red-list__tab--green.red-list__tab--default:after,
.red-list__tab--green:hover:after {
  background-color: var(--color-green);
}

.red-list__tab--orange[aria-selected="true"]:after,
.red-list__tab--orange.red-list__tab--default:after,
.red-list__tab--orange:hover:after {
  background-color: var(--color-orange);
}

.red-list__tab--redline[aria-selected="true"]:after,
.red-list__tab--redline.red-list__tab--default:after,
.red-list__tab--redline:hover:after {
  background-color: var(--color-white);
  border: 4px solid var(--color-red);
}

.red-list__content {
  position: relative;
  padding: var(--gutter);
  background: var(--color-rockgray);
  text-align: center;
  margin-left: calc(-1 * var(--half-gutter));
  margin-right: calc(-1 * var(--half-gutter));
  @media (--xsmall-up) {
    margin: 0;
  }
}

.red-list__content:focus {
  outline: none;
}

.red-list__arrow {
  position: absolute;
  top: -0.75rem;
  left: 0;
  width: calc(100% / 9);
  height: 1.5rem;
}

.red-list__arrow:before {
  content: '';
  width: 1.5rem;
  height: 1.5rem;
  border-top: 0.75rem solid var(--color-rockgray);
  border-right: 0.75rem solid var(--color-rockgray);
  border-bottom: 0.75rem solid transparent;
  border-left: 0.75rem solid transparent;
  transform: translate(-50%,0) rotate(-45deg);
  position: absolute;
  left: 50%;
}

.red-list__arrow--2 {
  left: calc((100% / 9) * 1);
}

.red-list__arrow--3 {
  left: calc((100% / 9) * 2);
}

.red-list__arrow--4 {
  left: calc((100% / 9) * 3);
}

.red-list__arrow--5 {
  left: calc((100% / 9) * 4);
}

.red-list__arrow--6 {
  left: calc((100% / 9) * 5);
}

.red-list__arrow--7 {
  left: calc((100% / 9) * 6);
}

.red-list__arrow--8 {
  left: calc((100% / 9) * 7);
}

.red-list__arrow--9 {
  left: calc((100% / 9) * 8);
}

.red-list__content-title {
  font-size: 2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: var(--font-wwf);
  margin: 0 0 1rem 0;
  text-align: center;
}
