/**
 * @title Select
 * @id components/form-select
 * @markup ../../styleguide/select.html
 */
.select {
  display: inline-block;
  position: relative;
  margin-bottom: 1rem; /* in case a sentence with two selects wraps to two lines */
}

.select > select {
  appearance: none;
  padding: 0 0.5em;
  padding-right: 1.5em;
  min-height: 28px;
  background: color(var(--color-white) alpha(0.8));
  color: var(--color-textblack);
  border: 1px solid transparent;
  border-bottom-color: var(--color-black);
  border-radius: 0;
  &::-ms-expand {
    display: none;
  }
  &:focus {
    border-color: var(--color-black);
    border-radius: 2px;
  }
}

.select--large > select {
  height: 3.125rem; /* 50px. With min-height, Firefox 119 aligns the select text strangely */
}

.select:after {
  content: '';
  pointer-events: none;
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translate(0,-50%);
  right: 0.5em;
  width: 0.5em;
  height: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  background-image: inline("images/arrow-down.svg");
}
