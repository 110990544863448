/**
 * @title Search results
 * @id components/search-results
 * @markup ../../styleguide/search-results.html
 * @width fullwidth
 * @background rockgray
 */
.search-results {
  padding: var(--gutter) 0;
}

.search-results__heading {
  margin: 0 0 var(--half-gutter) 1rem;
}

@media (--small-up) {
  .search-results {
    padding: var(--wide-gutter) 0;
  }

  .search-results__heading {
    margin-left: var(--gutter);
    margin-bottom: 2rem;
  }
}
