/**
 * @title Image flag
 * @id layouts/image-flag
 * @markup ../../styleguide/image-flag.html
 * @width xwide
 */

/* With the initial site layout, the text was aligned the same as for body text
 * on desktop and the image appeared to the left of that "column". There is no
 * longer enough room for that within the new grid (where body text is more
 * left-aligned). Thus it's possible this should be refactored to use the
 * "standard" l-wrapper machinery, if image-flag is even used/needed at all
 * anymore.
 */
.l-image-flag {
  margin: var(--wide-gutter) 0;
  @media (--medium-up) {
    display: flex;
    gap: var(--gutter);
  }
}

.l-image-flag__image {
  max-width: var(--grid-third);
  @media (--medium-up) {
    width: var(--grid-third);
    flex-basis: var(--grid-third);
  }
}

.l-image-flag__body {
  @media (--medium-up) {
    width: var(--grid-width);
    flex-basis: var(--grid-width);
  }
}

.l-image-flag__header {
  margin-top: var(--half-gutter);
  @media (--medium-up) {
    margin-top: 0;
  }
}

/**
 * @title Image flag logo
 * @id layouts/image-flag/logo
 * @markup ../../styleguide/image-flag--logo.html
 * @width xwide
 */

.l-image-flag__image--logo {
  @media (--medium-up) {
    display: flex;
    justify-content: center;
  }
}
