.o-only-screenreaders {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
}

.o-no-bottom-margin {
  margin-bottom: 0;
}

.o-hide-on-expanded[aria-expanded="true"] {
  display: none;
}

.o-center {
  text-align: center;
}

.o-hide {
  display: none;
}

.o-animate-in {
  animation: fade-in 600ms ease-out;
}

/*
.o-hide-focus:focus {
  outline: none;
}
*/
