/**
 * @title Body text
 * @id components/bodytext
 * @markup ../../styleguide/body-text.html
 */
.body-text {
  font: var(--font-body);
}

/* Duplicating the .ul component since Redactor contents don't have the
 * requisite class and it's not trivial to set
 */
.body-text > ol,
.body-text > ul {
  padding-left: 1.5rem;
  margin: 2rem 0 2rem 0;
  @media (--medium-up) {
    padding-left: 2.5rem;
  }
}

.body-text ol > li,
.body-text ul > li {
  list-style: none;
  position: relative;
  margin-bottom: 1rem;
}

.body-text ol ol,
.body-text ol ul,
.body-text ul ul,
.body-text ul ol {
  margin-top: 1rem;
  padding-left: var(--gutter);
}

.body-text ul > li::before {
  content: "● ";
  color: var(--color-blue);
  position: absolute;
  top: 0;
  left: 0;
  margin-left: -1.5rem;
}

.body-text ol {
  counter-reset: list;
}

.body-text ol > li::before {
  counter-increment: list;
  content: counter(list);
  font-weight: bold;
  position: absolute;
  top: 0;
  margin-left: -1.5rem;
  color: var(--color-blue);
}

/**
 * @title Compact
 * @id components/bodytext/compact
 * @markup ../../styleguide/body-text--compact.html
 */
.body-text--compact > ol,
.body-text--compact > ul {
  margin: 1rem 0 1rem 0;
  @media (--medium-up) {
    padding-left: 1.5rem;
  }
}
