/**
 * @title Search form
 * @id components/search-form
 * @markup ../../styleguide/search-form.html
 * @background beige
 */
.search-form {
  margin: var(--half-gutter) 0;
}

.search-form__label {
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.search-form__wrapper {
  position: relative;
}

.search-form__field {
  -webkit-appearance: textfield; /* hack to make Safari/macOS apply our CSS */
  width: 100%;
  border: none;
  border-radius: var(--gutter);
  background: var(--color-white);
  color: var(--color-textblack);
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.5;
  padding: 0.5rem 3.5rem 0.5rem 1rem;
}

.search-form__field:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--color-black);
}

.search-form__button {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  border: none;
  background: transparent;
  padding: 0 1rem;
  display: flex;
  align-items: center;
}

.search-form__button:focus {
  outline-offset: -0.5rem;
}

.search-form__icon {
  width: 1rem;
  height: 1rem;
}

/**
 * @title Dark background
 * @id components/search-form/dark-background
 * @markup ../../styleguide/search-form--dark-background.html
 * @background black
 */
.search-form--dark-background .search-form__label {
  color: var(--color-white);
}

.search-form--dark-background .search-form__field:focus {
  box-shadow: 0 0 0 2px var(--color-white), 0 0 0 2px var(--color-black) inset;
}

@media (--small-up) {
  .search-form__field {
    padding-left: 2rem;
  }
}
