@page {
  margin: 2.5cm 2cm;
}

@media print {
  html {
    font-size: 85%; /* Reduced fontsize in print 13.6px */
  }

  /* Hide elements in print */

  .header__menu-toggle-wrapper,
  .main-menu,
  .footer,
  .related-link,
  .cta-box,
  .cta-box__suffix-links,
  .image-box {
    display: none;
  }

  /* Elements that are visually adjusted for print */

  .header {
    background: none;
    margin-bottom: var(--gutter);
    box-shadow: none;
  }

  .bullet,
  .timeline__bullet,
  .red-list__list {
    -webkit-print-color-adjust: exact;
  }

  .figure {
    page-break-inside: avoid;
  }

  .fact-box__wrapper {
    display: flex;
    align-items: flex-start;
    page-break-inside: avoid;
  }

  .fact-box .fact-box__illustration {
    flex-basis: 33.333%;
  }

  .fact-box .fact-box__text {
    flex-basis: 66.666%;
    padding-left: var(--gutter);
  }

  .info-list--half {
    display: flex;
    align-items: flex-start;
  }

  .info-list--half .info-list__item {
    flex-basis: 50%;
  }

  .page-top--fade {
    padding-bottom: 0;
  }

  .page-top--fade .page-top__image-wrapper {
    margin-bottom: var(--gutter);
  }

  .page-top--fade.page-top--dark .page-top__image-wrapper:after {
    background: var(--color-white);
  }

  .tile__image-wrapper:after {
    display: none;
  }

  .page-top--dark,
  .page-top--dark .page-top__title,
  .page-top--dark .page-top__meta,
  .tile--dark .tile__text,
  .section--dark,
  .tile--dark,
  .tiles {
    background: none;
    color: var(--color-black);
  }

  .section {
    padding: 0;
  }
}
