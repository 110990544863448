/**
 * @title Page-top
 * @id components/page-top
 * @markup ../../styleguide/page-top.html
 * @width fullwidth
 */
.page-top {
  margin-bottom: var(--wide-gutter);
  position: relative;
  z-index: 0; /* Create stacking context for Chromium video workaround */
}

.page-top__text-wrapper {
  margin-top: var(--gutter);
  @media (--small-up) {
    margin-top: var(--wide-gutter);
  }
}

.page-top__title {
  margin-bottom: 2rem;
}

.page-top__intro {
  font: var(--font-intro-mobile);
  margin: 0 0 2rem 0;
  color: var(--color-green);
  @media (--small-up) {
    font: var(--font-intro-desktop);
  }
}

.page-top__meta {
  font-weight: 400;
  font-style: italic;
  margin: 0 0 1rem 0;
}

/**
 * @title Page-top with dark background
 * @id components/page-top/dark
 * @markup ../../styleguide/page-top--dark.html
 * @background black
 * @width fullwidth
 */
.page-top--dark-background .page-top__intro {
  color: var(--color-beige);
}

/**
 * @title Page-top with image
 * @id components/page-top/image
 * @markup ../../styleguide/page-top--image.html
 * @width fullwidth
 */
.page-top__image-wrapper picture {
  width: 100%;
}

.page-top__image,
.page-top__video {
  display: block;
  width: 100%;
  height: 250px;
  max-height: 60vh;
  object-fit: cover;
  object-position: center;
  @media (--small-up) {
    height: 480px;
  }
}

.page-top--with-image .page-top__text-wrapper {
  margin-top: 2.5rem;
}

.page-top--bg-primary,
.page-top--bg-secondary {
  padding-bottom: 1px;
}

.page-top--bg-primary {
  background: var(--color-orange);
}

.page-top--bg-primary .page-top__title,
.page-top--bg-primary .page-top__intro,
.page-top--bg-primary .page-top__meta {
  color: var(--color-black);
}

.page-top--bg-secondary {
  background: var(--color-beige);
}

.page-top--bg-primary .page-top__title,
.page-top--bg-primary .page-top__intro,
.page-top--bg-primary .page-top__meta {
  color: var(--color-black);
}

.page-top--bg-secondary {
  background: var(--color-beige);
}

/**
 * @title Page-top with overlay
 * @id components/page-top/overlay
 * @markup ../../styleguide/page-top--overlay.html
 * @width fullwidth
 */

.page-top--overlay {
  position: relative;
}

.page-top--overlay > .l-wrapper {
  display: flex; /* Hack to solve the loss of centering when using flexbox and min-height in IE 11 is to wrap the flexbox parent in a flexbox */
  flex-direction: column;
}

.page-top--overlay .page-top__image-wrapper {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: none;
  @media (--large-up) {
    overflow: hidden;
  }
}

.page-top--overlay .page-top__image {
  height: 100%;
  object-fit: cover;
}

.page-top--overlay .page-top__image-wrapper:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: color(var(--color-black) alpha(0.54));
}

.page-top--overlay .page-top__text-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-height: 50vh;
  margin: 0;
  padding: var(--gutter) 0;
  animation: fade-in 1200ms cubic-bezier(0.19, 1, 0.22, 1);
  @media (--medium-up) {
    min-height: 60vh;
  }
}

.page-top--overlay .page-top__title,
.page-top--overlay .page-top__intro {
  color: var(--color-white);
}

/**
 * @title Page-top with fade in white
 * @id components/page-top/fade
 * @markup ../../styleguide/page-top--fade.html
 * @width fullwidth
 */

.page-top--fade {
  background-color: var(--color-white);
  padding-bottom: var(--gutter);
  margin-bottom: 0;
}

.page-top--fade .page-top__image-wrapper {
  position: relative;
  z-index: 1;
  min-height: 12rem; /* If images are not loaded */
  background: var(--color-white);
}

.page-top--fade .page-top__image-wrapper:after {
  content: "";
  position: absolute;
  background: linear-gradient(rgba(255, 255, 255, 0), var(--color-white));
  bottom: 0;
  left: 0;
  right: 0;
  height: 150px;
  @media (--small-up) {
    height: 200px;
  }
  @media (--large-up) {
    height: 250px;
  }
}

.page-top--fade .page-top__text-wrapper {
  position: relative;
  z-index: 2;
  margin-top: -14%;
}

.page-top--fade .page-top__intro {
  color: var(--color-black);
}

.page-top__cta {
  margin-bottom: 1rem;
}

/**
 * @title Page-top with fade in black
 * @id components/page-top/fade-dark
 * @markup ../../styleguide/page-top--fade-dark.html
 * @width fullwidth
 */

.page-top--dark {
  background-color: var(--color-black);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.page-top--fade.page-top--dark .page-top__image-wrapper {
  background: var(--color-black);
}

.page-top--fade.page-top--dark .page-top__image-wrapper:after {
  background: linear-gradient(rgba(0, 0, 0, 0), var(--color-black));
}

.page-top--dark .page-top__title,
.page-top--dark .page-top__intro {
  color: var(--color-white);
}

.page-top--dark *:focus {
  outline-color: var(--color-white);
}

.page-top--dark .page-top__meta {
  color: var(--color-white);
}

/**
 * @title Page-top with fade in green
 * @id components/page-top/fade-green
 * @markup ../../styleguide/page-top--fade-green.html
 * @width fullwidth
 */
.page-top--green {
  background-color: var(--color-camaronegreen);
}

.page-top--fade.page-top--green .page-top__image-wrapper {
  background: var(--color-camaronegreen);
}

.page-top--fade.page-top--green .page-top__image-wrapper:after {
  background: linear-gradient(color(var(--color-camaronegreen) a(0)),
      var(--color-camaronegreen));
}

.page-top--green .page-top__title,
.page-top--green .page-top__intro,
.page-top--green .page-top__meta {
  color: var(--color-white);
}

/**
 * @title Page-top with a vertical fade (a modifier for fade)
 * @id components/page-top/fade-vertical
 * @markup ../../styleguide/page-top--fade-vertical.html
 * @width fullwidth
 */

/**
 * @title Page-top with a dark vertical fade (a modifier for fade)
 * @id components/page-top/fade-vertical-dark
 * @markup ../../styleguide/page-top--fade-vertical-dark.html
 * @width fullwidth
 */

/**
 * @title Page-top with a greeen vertical fade (a modifier for fade)
 * @id components/page-top/fade-vertical-green
 * @markup ../../styleguide/page-top--fade-vertical-green.html
 * @width fullwidth
 */
.page-top--fade.page-top--vertical-fade .page-top__title {
  margin-bottom: 1rem;
}

.page-top--fade.page-top--vertical-fade .page-top__intro {
  font: var(--font-body);
}

@media (--medium-up) {
  .page-top--fade.page-top--vertical-fade {
    padding-bottom: 0;
    position: relative;
  }

  .page-top--fade.page-top--vertical-fade .page-top__image-wrapper {
    max-width: 75%;
    margin-left: 25%;
    position: relative;
  }

  .page-top--fade.page-top--vertical-fade .page-top__image {
    height: auto;
    min-height: 25rem;
    max-height: 80vh;
  }

  .page-top--fade.page-top--vertical-fade .page-top__column-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
  }

  .page-top--fade.page-top--vertical-fade .page-top__text-wrapper {
    margin-top: 0;
    width: 50%;
    padding-top: var(--half-gutter);
  }

  /* Light gradient */
  .page-top--fade.page-top--vertical-fade .page-top__image-wrapper:after {
    right: auto;
    top: 0;
    height: auto;
    width: 33%;
    background: linear-gradient(
      to left,
      rgba(255, 255, 255, 0),
      var(--color-white)
    );
  }

  /* Dark gradient */
  .page-top--fade.page-top--vertical-fade.page-top--dark
      .page-top__image-wrapper:after {
    right: auto;
    top: 0;
    height: auto;
    width: 33%;
    background: linear-gradient(to left, rgba(0, 0, 0, 0), var(--color-black));
  }

  /* Green gradient */
  .page-top--fade.page-top--vertical-fade.page-top--green
      .page-top__image-wrapper:after {
    right: auto;
    top: 0;
    height: auto;
    width: 33%;
    background: linear-gradient(to left, color(var(--color-camaronegreen) a(0)),
        var(--color-camaronegreen));
  }
}

/**
 * @title Page-top with Pandaclub border
 * @id components/page-top/pandaclub
 * @markup ../../styleguide/page-top--pandaclub.html
 * @width fullwidth
 */

.page-top--pandaclub .page-top__image-wrapper {
  position: relative;
}

.page-top--pandaclub .page-top__image-wrapper:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background-repeat: repeat;
  height: 10px;
  background-size: 20px 20px;
  background-image: radial-gradient(
    circle at 10px -5px,
    transparent 12px,
    white 13px
  );
}

.page-top--pandaclub .page-top__image-wrapper:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background-repeat: repeat;
  height: 15px;
  background-size: 40px 20px;
  background-image: radial-gradient(
    circle at 10px 15px,
    white 12px,
    transparent 13px
  );
}

/**
 * @title Page-top with video
 * @id components/page-top/video
 * @markup ../../styleguide/page-top--video.html
 * @width fullwidth
 */
.page-top__video-controls {
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 1; /* Workaround to make button clickable in Chromium */
}
