/**
 * @title Figure (image)
 * @id components/figure
 * @markup ../../styleguide/figure.html
 */
.figure {
  margin: 0;
  background: var(--color-rockgray);
  border-radius: 9px;
  overflow: hidden;
}

.figure__image {
  display: block;
  width: 100%;
  background: var(--color-rockgray);
  margin: 0;
}

.figure__text-wrapper {
  display: block;
  padding: 1.5rem var(--half-gutter);
  @media (--small-up) {
    padding: 1.5rem;
  }
}

.figure__caption {
  display: block;
  margin-bottom: 0.5rem;
  font: var(--font-body);
}

.figure__caption:last-child {
  margin-bottom: 0;
}

.figure__legal {
  font-size: 0.625rem;
  line-height: 1.344;
  text-transform: uppercase;
  color: var(--color-darkgray);
  letter-spacing: 1px;
  @media (--large-up) {
    font-size: 0.56rem;
    line-height: 1.5;
  }
}

/**
 * @title Figure (image) - portrait
 * @id components/figure/portrait
 * @markup ../../styleguide/figure--portrait.html
 */
.figure--portrait {
  max-width: var(--grid-half);
  @media (--medium-up) {
    max-width: inherit;
  }
}

/**
 * @title Figure (image) - Wide
 * @id components/figure/wide
 * @markup ../../styleguide/figure--wide.html
 * @width imagebox
 */

/**
 * @title Figure (image) - Fullwidth
 * @id components/figure/full
 * @markup ../../styleguide/figure--fullwidth.html
 * @width fullwidth
 */

.figure--full {
  border-radius: 0;
}

/**
 * @title Figure (video)
 * @id components/figure/video
 * @markup ../../styleguide/figure--video.html
 */

/**
 * @title Figure (video) - Wide
 * @id components/figure/video-wide
 * @markup ../../styleguide/figure--video-wide.html
 * @width imagebox
 */
.figure__video-wrapper {
  position: relative;
  padding-top: calc(9 / 16 * 100%); /* Could be overridden based on video format */
  background: var(--color-beige);
}

.figure__iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

/**
 * @title Figure (image) - Compact
 * @id components/figure/compact
 * @markup ../../styleguide/figure--compact.html
 */
.figure--compact .figure__text-wrapper {
  padding: 0.5rem;
  padding-bottom: 0;
}

/**
 * @title Figure (image) - Transparent
 * @id components/figure/transparent
 * @markup ../../styleguide/figure--transparent.html
 */
.figure--transparent {
  background: transparent;
  border-radius: 0;
}

/**
 * Only for use in floating factbox.
 *
 * @title Figure (image) - White
 * @id components/figure/white
 * @markup ../../styleguide/figure--white.html
 */
.figure--white {
  background: var(--color-white);
  border-radius: 0;
}

.figure--white .figure__image {
  border-radius: 0 0 9px 9px;
}
