button,
input,
optgroup,
option,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  text-transform: inherit;
}

label {
  display: block;
}

input[type="text"],
input[type="number"],
input[type="email"],
input[type="tel"],
input[type="date"],
input[type="time"],
textarea {
  appearance: none;
  font-family: inherit;
  font-size: 1rem;
  line-height: inherit;
  background: color(var(--color-white) alpha(0.4));
  border: 1px solid var(--color-black);
  border-radius: 2px;
  padding: 0 0.5rem; /* vertical padding may cause bugs in some browsers */
  min-height: 2.75rem;
  width: 100%;
  &::placeholder {
    color: color(var(--color-black) alpha(0.5));
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px var(--color-black);
  }
  &:disabled {
    opacity: 0.5;
  }
}

textarea {
  padding: 0.5rem;
}

select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  background: color(var(--color-white) alpha(0.4));
  color: inherit;
  border: 1px solid var(--color-black);
  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px var(--color-black);
  }
  &:disabled {
    opacity: 0.5;
  }
}

button {
  appearance: none;
  font-family: inherit;
  font-size: 1rem;
  cursor: pointer;
}

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}

legend {
  padding: 0;
}
