/**
 * @title Icon
 * @id components/icon
 * @markup ../../styleguide/icon.html
 */

/**
 * @title Icon in button
 * @id components/icon/button
 * @markup ../../styleguide/icon--button.html
 */

.icon {
  display: block;
  width: var(--half-gutter);
  height: var(--half-gutter);
}

.icon[hidden] {
  display: none;
}

.icon svg {
  vertical-align: top;
  width: 100%;
  height: 100%;
  fill: currentColor;
}
