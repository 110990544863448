/**
 * @title Related link
 * @id components/related-link
 * @markup ../../styleguide/related-link.html
 */

.related-link {
  background: var(--color-white);
  color: var(--color-textblack);
  border-radius: 9px;
  display: block;
  overflow: hidden;
  margin-bottom: var(--gutter);
  border: none;
  &:hover,
  &:focus {
    background-color: var(--color-white);
  }
}

.related-link__content {
  padding: 1rem;
  position: relative;
  @media (--small-up) {
    padding: var(--gutter);
  }
  @media (--large-up) {
    padding-right: 4rem;
  }
}

.related-link__title {
  text-decoration: underline;
  @media (--large-up) {
    text-decoration: none;
  }
}

.related-link__title:last-child, .related-link__text:last-child {
  margin-bottom: 0;
}

.related-link__content:after {
  @media (--large-up) {
    content: "";
    width: 1rem;
    height: 1rem;
    border-right: 2px solid var(--color-black);
    border-bottom: 2px solid var(--color-black);
    transform: translate(0, -50%) translate(0, 0) rotate(-45deg);
    transition: transform 0.05s ease-in-out;
    position: absolute;
    top: 50%;
    right: 2rem;
  }
}

.related-link:hover .related-link__content:after {
  @media (--large-up) {
    transform: translate(0, -50%) translate(4px, 0) rotate(-45deg);
  }
}

.related-link__title {
  margin-top: 0;
}

.related-link__image {
  display: block;
  width: 100%;
}

.related-link__text {
  font: var(--font-body);
}

/**
 * @title Prioritized
 * @id components/related-link/prioritized
 * @markup ../../styleguide/related-link--prioritized.html
 * @width wide
 */
.related-link--prioritized {
  background: var(--color-lightyellow);
  &:hover,
  &:focus {
    background-color: var(--color-lightyellow);
  }
  @media (--small-up) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.related-link--prioritized .related-link__image {
  aspect-ratio: 1.84;
  object-fit: cover;
  @media (--small-up) {
    aspect-ratio: auto;
    flex: 33%;
    align-self: stretch;
    min-width: 0;
  }
}

.related-link--prioritized .related-link__content {
  @media (--small-up) {
    flex: 67%;
  }
}

/**
 * @title Related link - Secondary
 * @id components/related-link/secondary
 * @markup ../../styleguide/related-link--secondary.html
 * @width fullwidth
 */

.related-link--secondary {
  background: var(--color-rockgray);
  &:hover,
  &:focus {
    background: var(--color-rockgray);
  }
}

.related-link--secondary .related-link__title {
  font-family: var(--font-opensans);
  font-size: 1rem;
  font-weight: 600;
  text-transform: none;
  letter-spacing: normal;
  margin-bottom: 0.5rem;
}

.related-link--secondary .related-link__text {
  font-size: 0.95rem;
}

.related-link--secondary .related-link__content {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

/**
 * @title Dark background
 * @id components/related-link/darkbackground
 * @markup ../../styleguide/related-link--darkbackground.html
 * @background black
 */
.related-link--darkbackground:focus {
  outline-color: var(--color-white);
}

/**
 * @title Black
 * @id components/related-link/black
 * @markup ../../styleguide/related-link--black.html
 */
.related-link--black {
  background: var(--color-black);
  &:hover,
  &:focus {
   background-color: var(--color-black);
  }
  &:focus {
    outline-offset: 2px;
  }
}
.related-link--black .related-link__title,
.related-link--black .related-link__text {
  color: var(--color-white);
}

.related-link--black .related-link__content:after {
  @media (--large-up) {
    border-right-color: var(--color-white);
    border-bottom-color: var(--color-white);
  }
}

/**
 * @title Dark background, black
 * @id components/related-link/darkbackground-black
 * @markup ../../styleguide/related-link--darkbackground-black.html
 * @background black
 */
.related-link--black.related-link--darkbackground {
  background-color: color(var(--color-black) tint(15%));
}

/**
 * @title Related link with image that is used in a narrow layout
 * @id components/related-link/with-image-narrow
 * @markup ../../styleguide/related-link--image-narrow.html
 * @width fullwidth
 */

.related-link--narrow {
  display: flex;
  flex-direction: column;
  @media (width < 1220px) {
    hyphens: auto;
    word-wrap: break-word;
  }
}

.related-link--narrow .related-link__image {
  width: 100%;
  object-fit: fill;
  height: 100%; /* Should be auto but IE likes 100% better. Does not cause stretching in other browsers due to object fit. */
}

.related-link--narrow .related-link__content {
  padding: 1rem;
  padding-bottom: 4rem;
  height: 100%;
}

.related-link--narrow .related-link__content:after {
  @media (--large-up) {
    transform: translate(-50%, 0) translate(0, 0) rotate(-45deg);
    top: auto;
    right: auto;
    bottom: 1.5rem;
    left: 1rem;
  }
}

.related-link--narrow:hover .related-link__content:after {
  @media (--large-up) {
    transform: translate(-50%, 0) translate(4px, 0) rotate(-45deg);
  }
}

/**
 * @title Desert color theme
 * @id components/related-link/desert
 * @markup ../../styleguide/related-link--desert.html
 */
.related-link--desert {
  background: var(--color-darkbrown);
  color: var(--color-white);
  &:hover,
  &:focus {
    background-color: var(--color-darkbrown);
  }
  &:focus {
    outline-offset: 2px;
  }
}

.related-link--desert.related-link--darkbackground {
  background-color: color(var(--color-darkbrown) tint(15%));
}

.related-link--desert .related-link__content:after {
  @media (--large-up) {
    border-color: var(--color-white);
  }
}

/**
 * @title Garden color theme
 * @id components/related-link/garden
 * @markup ../../styleguide/related-link--garden.html
 */
.related-link--garden {
  background: var(--color-darkgreen);
  color: var(--color-white);
  &:hover,
  &:focus {
    background-color: var(--color-darkgreen);
  }
  &:focus {
    outline-offset: 2px;
  }
}

.related-link--garden.related-link--darkbackground {
  background-color: color(var(--color-darkgreen) tint(15%));
}

.related-link--garden .related-link__content:after {
  @media (--large-up) {
    border-color: var(--color-white);
  }
}

/**
 * @title Ocean color theme
 * @id components/related-link/ocean
 * @markup ../../styleguide/related-link--ocean.html
 */
.related-link--ocean {
  background: var(--color-darkblue);
  color: var(--color-white);
  &:hover,
  &:focus {
    background-color: var(--color-darkblue);
  }
  &:focus {
    outline-offset: 2px;
  }
}

.related-link--ocean.related-link--darkbackground {
  background-color: color(var(--color-darkblue) tint(15%));
}

.related-link--ocean .related-link__content:after {
  @media (--large-up) {
    border-color: var(--color-white);
  }
}
