/**
 * @title Breadcrumbs
 * @id components/breadcrumbs
 * @markup ../../styleguide/breadcrumbs.html
 */

.breadcrumbs {
  margin-bottom: var(--half-gutter);
}

.breadcrumbs__item {
  display: inline-block;
  font-size: 0.85rem;
  &:before {
    content: "/";
    color: var(--color-textblack);
  }
}

/**
 * @title Light
 * @id components/breadcrumbs/light
 * @markup ../../styleguide/breadcrumbs--light.html
 * @background black
 */
.breadcrumbs--light .breadcrumbs__item:before {
  color: var(--color-white);
}

.breadcrumbs--light .breadcrumbs__link {
  color: var(--color-white);
  border-bottom-color: var(--color-white);
  outline-color: var(--color-white);
  &:hover,
  &:focus {
    background-color: var(--color-textblack);
  }
}

/**
 * @title Single back button
 * @id components/breadcrumbs/single
 * @markup ../../styleguide/breadcrumbs--single.html
 */
