/**
 * @title Fact box
 * @id components/fact-box
 * @markup ../../styleguide/fact-box.html
 * @width fullwidth
 */

.fact-box {
  background: var(--color-rockgray);
}

.fact-box--floater {
  border-radius: 9px;
}

.fact-box__text {
  padding: var(--half-gutter);
  @media (--small-up) {
    padding: 1.5rem;
  }
}

.fact-box__title {
  font: var(--font-h3);
  margin: 0 0 1rem 0;
}

.fact-box--floater .fact-box__wrapper {
  display: flex;
  flex-direction: column;
}

.fact-box--floater .fact-box__illustration {
  order: 2;
  flex: 0 0 auto; /* Needed in IE */
}

.fact-box--floater .fact-box__text {
  order: 1;
  flex: 0 0 auto; /* Needed in IE */
}

/**
 * @title Fact box large
 * @id components/fact-box/large
 * @markup ../../styleguide/fact-box--large.html
 * @width fullwidth
 */

.fact-box--large {
  margin-top: var(--wide-gutter);
  margin-bottom: var(--wide-gutter);
}

.fact-box--large .fact-box__title {
  font: var(--font-h2-mobile);
  @media (--large-up) {
    font: var(--font-h2-desktop);
  }
}

.fact-box--large .fact-box__text {
  @media (--small-up) {
    padding: 0 var(--gutter);
  }
  @media (--large-up) {
    padding: 0 var(--wide-gutter);
  }
}

.fact-box--large .fact-box__wrapper {
  @media (--small-up) {
    display: flex;
    align-items: flex-start;
    max-width: var(--grid-width-wide);
    margin: 0 auto;
    padding: var(--wide-gutter) var(--gutter);
  }
  @media (--medium-up) {
    align-items: center;
  }
}

.fact-box--large .fact-box__illustration {
  @media (--small-up) {
    flex-basis: 33.333%;
  }
}

.fact-box--large .fact-box__text {
  @media (--small-up) {
    flex-basis: 66.666%;
  }
}

/**
 * @title Animal
 * @id components/fact-box/animal
 * @markup ../../styleguide/fact-box--animal.html
 * @width fullwidth
 */
.fact-box__animal {
  margin: 3rem 0;
}

.fact-box__animal-names,
.fact-box__animal-status {
  margin-bottom: 2rem;
}

.fact-box__animal-heading {
  font-size: 2rem;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: var(--font-wwf);
  margin: 0;
}

@media (--small-up) {
  .fact-box__animal-heading {
    font-size: 2.25rem;
  }
}

@media (--large-up) {
  .fact-box__animal {
    margin: 4rem calc(-1 * var(--gutter)) 0;
    display: flex;
  }

  .fact-box__animal-names,
  .fact-box__animal-status {
    margin: 0 var(--gutter);
  }

  .fact-box__animal-names {
    width: 30%;
  }
}
