/**
 * @title Related download
 * @id components/related-download
 * @markup ../../styleguide/related-download.html
 * @width narrow
 */
.related-download {
  background: var(--color-rockgray);
  border-radius: 6px;
  overflow: hidden; /* Unfortunately needed to clip to border-radius */
}

.related-download__figure {
  margin: 0;
  text-align: center;
}

.related-download__image {
  vertical-align: top;
  width: 100%;
}

.related-download__header {
  margin: 0 var(--half-gutter);
}

.related-download__label {
  font: var(--font-body);
  margin: 2rem 0 0;
}

.related-download__heading {
  font: var(--font-h3);
  margin: 0.5rem 0;
}

.related-download__strapline {
  font: var(--font-body);
  font-style: italic;
}

.related-download__body {
  font: var(--font-body);
  margin: 1rem var(--half-gutter) 2rem;
}

/**
 * @title Portrait
 * @id components/related-download/portrait
 * @markup ../../styleguide/related-download--portrait.html
 * @width narrow
 */
.related-download--portrait {
  padding-top: 1rem;
}

.related-download--portrait .related-download__image {
  width: auto;
  max-height: 300px;
  max-width: 100%;
}

@media (--small-up) {
  .related-download__header,
  .related-download__body {
    margin-left: var(--gutter);
    margin-right: var(--gutter);
  }
}
