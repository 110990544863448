/**
 * @title Inline link list
 * @id components/inline-link-list
 * @markup ../../styleguide/inline-link-list.html
 */

.inline-link-list {
}

.inline-link-list__item {
  margin-bottom: 0.5rem;
  font-size: 0.85rem;
  @media (--small-up) {
    display: inline-block;
    &:after {
      content: '|';
      color: var(--color-darkbeige);
    }
    &:last-child:after {
      display: none;
    }
  }
}

/**
 * @title Inline link list dark
 * @id components/inline-link-list/dark
 * @markup ../../styleguide/inline-link-list--dark.html
 * @background black
 */

.inline-link-list--dark .inline-link-list__item > a {
  color: var(--color-white);
  border-bottom-color: var(--color-white);
  &:hover, &:focus {
    background-color: var(--color-textblack);
  }
  &:focus {
    outline-color: var(--color-white);
  }
}
