/**
 * @title Download link
 * @id components/download-link
 * @markup ../../styleguide/download-link.html
 */
.download-link {
  display: block;
  border-bottom: none;
  padding: var(--half-gutter);
  padding-left: 3rem;
  color: var(--color-textblack);
  background-color: var(--color-alto);
  background-image: inline("images/download-dark.svg");
  background-repeat: no-repeat;
  background-size: 2rem;
  background-position: 0.5rem var(--half-gutter);
  border-radius: 6px;
  transition: background-color 0.05s ease-in-out, box-shadow 0.05s ease-in-out;
  box-shadow: 0 0 0 0 var(--color-alto); /* Base for transition */
}

.download-link:hover,
.download-link:focus {
  background-color: color(var(--color-alto) blend(white 15%));
  box-shadow: 0 0 0 2px color(var(--color-alto) blend(white 15%));
}

.download-link__name {
  font-weight: 600;
  text-decoration: underline;
}

.download-link__meta {
  display: block;
  font-size: 0.625rem;
  line-height: 1.344;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 0.5rem;
  @media (--large-up) {
    font-size: 0.56rem;
    line-height: 1.5;
  }
}

@media (--small-up) {
  .download-link {
    padding: 1.5rem;
    padding-left: 5.75rem;
    background-size: 3.75rem;
    background-position: var(--half-gutter);
  }
}

/* Alternating color within .download-list */
.download-list__item .download-link {
  border-radius: 0;
}

.download-list__item:nth-child(even) .download-link {
  background-color: var(--color-rockgray);
  box-shadow: 0 0 0 0 var(--color-rockgray);
}

.download-list__item:nth-child(even) .download-link:hover,
.download-list__item:nth-child(even) .download-link:focus {
  background-color: color(var(--color-rockgray) blend(white 15%));
  box-shadow: 0 0 0 2px color(var(--color-rockgray) blend(white 15%));
}

/**
 * @title Primary
 * @id components/download-link/primary
 * @markup ../../styleguide/download-link--primary.html
 */
.download-link--primary {
  color: var(--color-white);
  background-color: var(--color-textblack);
  background-image: inline("images/download.svg");
  box-shadow: 0 0 0 0 var(--color-textblack);
}

.download-link--primary:hover,
.download-link--primary:focus {
  background-color: color(var(--color-textblack) blend(white 15%));
  box-shadow: 0 0 0 2px color(var(--color-textblack) blend(white 15%));
}
