/**
 * @title Message
 * @id components/message
 * @markup ../../styleguide/message.html
 */

.message {
  text-align: left;
  border-left: 4px solid var(--color-green);
  padding-left: 1rem;
  background: var(--color-white);
  padding: var(--half-gutter);
  animation: fade-in-down 300ms ease-out;
}

.message > p:last-child {
  margin-bottom: 0;
}

/**
 * @title Error message
 * @id components/message/error
 * @markup ../../styleguide/message--error.html
 */

.message--error {
  border-left-color: var(--color-red);
  color: var(--color-red);
}

.message--error .message__link:hover,
.message--error .message__link:focus {
  background-color: transparent; /* lightblue is too low contrast */
}

/**
 * @title Preview
 * @id components/message/preview
 * @markup ../../styleguide/message--preview.html
 */
.message--preview {
  border-left-width: 0;
  animation: none;
}
