/**
 * @title Poll meter
 * @id components/poll-meter
 * @markup ../../styleguide/poll-meter.html
 * @background yellow
 */
.poll-meter {
  margin: 1rem 0;
}

.poll-meter__options {
  display: flex;
  justify-content: space-between;
}

.poll-meter__option {
  flex: 0;
  font-family: var(--font-wwf);
  font-size: 2rem;
  text-transform: uppercase;
}

.poll-meter__meter {
  background: var(--color-white);
  display: flex;
  width: 100%;
  min-height: 4.25rem;
}

.poll-meter__percentage-a,
.poll-meter__percentage-b {
  display: flex; /* Just for vertically centering the text */
  align-items: center;
  padding: 0.5rem;
  font-weight: 600;
}

.poll-meter__percentage-a {
  color: var(--color-white);
  background: var(--color-black);
  flex: 0;
  justify-content: center;
  animation: poll-meter 700ms var(--ease-in-quart);
}

.poll-meter__percentage-b {
  flex: 1;
  justify-content: flex-end;
}
