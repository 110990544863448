/**
 * @title Layout - Listpage
 * @id layouts/page-list
 * @markup ../../styleguide/page-list.html
 * @width fullwidth
 */

/**
 * @title Layout - News list
 * @id layouts/page-news-list
 * @markup ../../styleguide/page-news-list.html
 * @width fullwidth
 */
.l-list-page {
  color: var(--color-white);
  background: var(--color-black);
  padding-bottom: var(--gutter);
  position: relative;
  overflow-y: hidden; /* picture might overflow */
}

.l-list-page__picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 600px;
}

.l-list-page__picture::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  background-image: linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 27%);
  background-size: 100%;
}

.l-list-page__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (--small-up) {
  .l-list-page {
    padding-bottom: var(--wide-gutter);
  }
}

@media (width >= 1600px) {
  .l-list-page__picture {
    height: auto;
  }
}
