/**
 * @title Subheading
 * @id components/subheading
 * @markup ../../styleguide/subheading.html
 */
/**
 * @title with anchor
 * @id components/subheading/anchor
 * @markup ../../styleguide/subheading--anchor.html
 */
.subheading__anchor {
  font-family: var(--font-opensans);
  font-size: 1rem;
  display: inline-block;
  width: 1rem;
  margin-left: -1rem;
  text-align: center;
  color: var(--color-gray);
  border-bottom-color: var(--color-gray);
}
