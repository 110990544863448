/**
 * @title Form field
 * @id components/form-field
 * @markup ../../styleguide/form-field.html
 */
.form-field {
  margin-bottom: var(--half-gutter);
}

.form-field--deletable {
  position: relative;
  padding-right: 3.5rem;
}

.form-field__label {
  font-size: 0.875rem;
  display: block;
  margin-bottom: 0.25rem;
  font-weight: normal;
}

.form-field__label-hint {
  font-weight: normal;
  opacity: 0.75;
}

.form-field__description,
.form-field__error {
  margin-bottom: 0;
  margin-top: 0.25rem;
  font-size: 0.875rem;
}

.form-field__error {
  color: var(--color-red);
}

.form-field__control {
  display: inline-block;
  width: 100%;
  @media (--xsmall-up) {
    max-width: 17rem;
  }
}

.form-field__control--full {
  max-width: none;
}

.form-field__control--large {
  @media (--xsmall-up) {
    max-width: 22rem;
  }
}

.form-field__control--small {
  max-width: 10rem;
}

.form-field__control--xsmall {
  max-width: 5rem;
}

.form-field__control--auto,
input.form-field__control--auto {
  width: auto;
  max-width: none;
}

.form-field--error {
  border-left: 2px solid var(--color-red);
  padding-left: 1rem;
  margin-left: -1rem;
}

.form-field--error .form-field__control {
  border-color: var(--color-red);
  &:focus {
    border-color: var(--color-black);
  }
}

.form-field__control-suffix {
  display: inline-block;
  margin-left: var(--half-gutter);
}

.form-field--disabled .form-field__label,
.form-field--disabled .form-field__description {
  opacity: 0.5;
}

.form-field--inline {
  margin-bottom: 0;
}

.form-field--inline .form-field__control {
  font-size: inherit; /* For vertical alignment */
  margin-bottom: 1rem; /* In case controls wrap below each other */
  width: auto;
}

.form-field__delete {
  position: absolute;
  top: 1.5rem;
  right: 0;
}
