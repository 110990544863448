/**
 * @title Seafood footer
 * @id components/seafood-footer
 * @markup ../../styleguide/seafood-footer.html
 * @width fullwidth
 */
.seafood-footer {
  background: url(/resources/images/seafood-background.jpg) var(--color-black);
  background-size: cover;
  padding: 4rem 0;
  margin-top: calc(2 * var(--wide-gutter));
}

.seafood-footer__heading,
.seafood-footer__lead {
  color: var(--color-white);
  text-align: center;
}

.seafood-footer__lead {
  margin-bottom: var(--wide-gutter);
  font-size: 1.2rem;
}

.seafood-footer__categories {
  margin: var(--wide-gutter) 0;
}

.seafood-footer__category {
  margin-bottom: var(--half-gutter);
}

.seafood-footer__category-link {
  display: block;
  border-bottom: none;
  background: var(--color-white);
  padding: 1.25rem 3rem;
  border-radius: 9px;
  font-size: 1.5rem;
  font-weight: 600;
  outline-offset: -3px;
  position: relative;
}

.seafood-footer__category-link:before {
  content: "";
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 1.625rem; /* 1.5rem tall disc centered in 1.5*1.5rem+2*1.25rem box */
  left: 1rem;
  border-radius: 50%;
}

.seafood-footer__category-link--green:before {
  background: var(--color-green);
}

.seafood-footer__category-link--yellow:before {
  background: var(--color-yellow);
}

.seafood-footer__category-link--red:before {
  background: var(--color-red);
}

.seafood-footer__category-link:after {
  content: "";
  position: absolute;
  top: 1.875rem; /* 1rem tall object centered in 1.5*1.5rem+2*1.25rem box */
  right: 2rem;
  width: 1rem;
  height: 1rem;
  border-right: 2px solid var(--color-black);
  border-bottom: 2px solid var(--color-black);
  transform: rotate(-45deg);
}
