/**
 * @title Image box
 * @id components/image-box
 * @markup ../../styleguide/image-box.html
 * @width imagebox
 */

.image-box {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin: 0 calc(-1 * var(--half-gutter));
  @media (--small-up) {
    margin-left: 0;
    margin-right: 0;
  }
  @media (--medium-up) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.image-box__image {
  display: block;
  background: var(--color-darkgray);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 18rem;
  @media (--small-up) {
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    min-height: 28rem;
  }
  @media (--medium-up) {
    flex: 45%;
    max-height: 45rem;
    border-top-right-radius: 0;
    border-bottom-left-radius: 9px;
  }
}

.image-box__box {
  overflow: hidden;
  @media (--small-up) {
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
  }
  @media (--medium-up) {
    flex: 55%;
    border-top-right-radius: 9px;
    border-bottom-left-radius: 0;
  }
}

.image-box__legal {
  font-size: 0.625rem;
  line-height: 1.344;
  text-transform: uppercase;
  color: var(--color-darkgray);
  letter-spacing: 1px;
  @media (--large-up) {
    font-size: 0.56rem;
    line-height: 1.5;
  }
}

.image-box__box-suffix {
  margin-top: 1rem;
  @media (--small-up) {
    padding: 0 var(--gutter);
  }
  @media (--medium-up) {
    margin-left: 45%;
  }
  @media (--large-up) {
    padding: 0 5rem;
  }
}
