/**
 * @title Header
 * @id components/header
 * @markup ../../styleguide/header.html
 * @width fullwidth
 */
.header {
  background: var(--color-black);
  position: relative;
  box-shadow: 0 4px 16px 0 rgba(0,0,0,0.25);
  z-index: 0; /* Establish stacking context */
}

.header__wrapper {
  @media (--small-up) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: flex-start;
  }
}

.header__ident-wrapper {
  margin: 0; /* for the case where it's h1 */
  flex: none;
  background: var(--color-white);
}

.header__ident {
  display: block;
  width: 100%;
  position: relative;
  border: none;
  background: var(--color-white);
  padding: 0 0.25rem;
  width: 4rem;
  @media (--small-up) {
    width: 6.5rem;
    padding: 0.25rem var(--half-gutter);
  }
  &:focus, &:hover {
    border: none;
    background: none;
  }
  &:focus {
    outline-offset: -5px;
  }
}

.header__ident-image {
  display: block;
  width: 100%;
}

.header__menu-toggle-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  @media (--small-up) {
    display: none;
  }
}

.header__menu-toggle {
  color: inherit; /* iOS Safari sets blue button color */
  background: transparent;
  border: none;
  margin: 0;
  height: 4rem;
  padding: var(--half-gutter);
  font: 700 1.5rem/1 var(--font-wwf);
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  outline-offset: -3px;
}

.header__menu-toggle[aria-expanded=true] {
  background-color: var(--color-yellow);
  width: 4rem;
}

.header__menu-toggle:after {
  content: '';
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  vertical-align: top;
  margin-left: var(--half-gutter);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  background-image: inline("images/hamburger.svg");
}

.header__menu-toggle[aria-expanded=true]:after {
  background-image: inline("images/close-black.svg");
  margin-left: 0;
}

.header__main-menu-wrapper {
  display: none;
  padding: var(--half-gutter);
  @media (--small-up) {
    display: flex;
    padding: 0.5rem var(--gutter);
    align-items: center;
    margin: 0 auto;
    /* width of 12 columns + logo width + paddings, to align text start with l-wrapper contents */
    max-width: calc(var(--grid-header) + 6.5rem + 6rem);
    flex: 1;
  }
}

.header__search {
  padding: var(--half-gutter);
}

@media (max-width: 599.99px) { /* Fix avoid PostCSS translation errors using width < 600px */
  .header {
    background: var(--color-white);
  }

  .header__ident-wrapper {
    position: relative;
    z-index: 1;
  }

  .header__main-menu-wrapper.is-expanded {
    display: block;
    background: var(--color-black);
    animation: fade-in-down 300ms ease-out;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .header__search {
    order: -1;
  }
}
