/**
 * @title Seafood search header
 * @id components/seafood-search-header
 * @markup ../../styleguide/seafood-search-header.html
 * @width fullwidth
 */
.seafood-search-header__main {
  background: url(/resources/images/seafood-background.jpg) center var(--color-black);
  background-size: cover;
  color: var(--color-white);
  padding: var(--wide-gutter) 0;
}

.seafood-search-header__top {
  text-align: center;
  margin-bottom: var(--gutter);
}

.seafood-search-header__lead {
  font-size: 1.2rem;
}

.seafood-search-header__breadcrumbs {
  display: inline-block;
}

.seafood-search-header__filters {
  margin: var(--gutter) 0 var(--half-gutter);
}

.seafood-search-header__legal {
  padding: 1.5rem var(--half-gutter);
  background: var(--color-rockgray);
  color: var(--color-darkgray);
  font-size: 0.625rem;
  line-height: 1.344;
  text-transform: uppercase;
  letter-spacing: 1px;
  @media (--large-up) {
    font-size: 0.56rem;
    line-height: 1.5;
  }
}

@media (--small-up) {
  .seafood-search-header__legal {
    padding: 1.5rem var(--gutter);
  }
}

@media (--medium-up) {
  .seafood-search-header__filters {
    width: 66.666%;
  }
}

@media (--large-up) {
  .seafood-search-header__main {
    padding-top: calc(3 * var(--wide-gutter));
  }

  .seafood-search-header__filters {
    margin-top: var(--wide-gutter);
  }
}

@media (--xlarge-up) {
  .seafood-search-header__main {
    padding-top: calc(4 * var(--wide-gutter));
  }
}
