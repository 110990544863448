/**
 * @title Banner
 * @id components/banner
 * @markup ../../styleguide/banner.html
 * @width wide
 */

.banner {
  display: block;
  overflow: hidden;
  position: relative;
  background: var(--color-white);
  border-radius: 9px;
  transition: box-shadow 0.05s ease-in-out;
  &:hover {
    box-shadow: 0 0 0 2px color(var(--color-black) alpha(0.85));
  }
}


.banner:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: color(var(--color-black) alpha(0.54));
  transition: opacity 0.05s ease-in-out;
}

.banner:focus:before,
.banner:hover:before {
  opacity: 0.85;
}

.banner__image {
  display: block;
}

.banner__content {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner__title {
  font-size: 2rem;
  line-height: 1.25;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: var(--font-wwf);
  margin: 0 var(--wide-gutter);
  color: var(--color-white);
  text-align: center;
  @media (--small-up) {
    font-size: 2.25rem;
    line-height: 2.75rem;
  }
  @media (--large-up) {
    font-size: 3rem;
    line-height: 1.175;
  }
}

.banner__title:after {
  content: "";
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-right: 2px solid var(--color-white);
  border-bottom: 2px solid var(--color-white);
  transform: translate(0, -50%) rotate(-45deg);
}
