/**
 * @title Petition statement
 * @id components/petition-statement
 * @markup ../../styleguide/petition-statement.html
 */
.petition-statement__heading {
  font-size: 2rem;
  line-height: 1.25;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: var(--font-wwf);
  margin-top: 0;
}

.petition-statement__body {
  font: var(--font-body);
}

.petition-statement__body ol,
.petition-statement__body ul {
  margin-bottom: 1rem;
}

.petition-statement__body ol > li {
  list-style: decimal;
  margin-left: 1em;
}

.petition-statement__body ul > li {
  list-style: disc;
  margin-left: 1em;
}
