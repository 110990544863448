/**
 * @title Download list
 * @id components/download-list
 * @markup ../../styleguide/download-list.html
 */
.download-list {
}

.download-list__item {
  margin-bottom: 1px;
}
