/**
 * @title Tile
 * @id components/tile
 * @markup ../../styleguide/tile.html
 */
.tile {
  border: none;
  margin-bottom: var(--half-gutter);
  &:hover,
  &:focus {
    background: none;
  }
  &:focus {
    outline: none; /* Rely on text underline and image outline */
  }
}

.tile__image {
  display: block;
  width: 100%;
  border-radius: 6px;
  background: var(--color-darkgray);
}

.tile:hover .tile__image {
  outline: 1px solid var(--color-black);
}

.tile:focus .tile__image {
  outline: 2px solid var(--color-black);
  outline-offset: 3px;
}

.tile__text {
  display: block;
  text-align: left;
  padding-top: 0.5rem;
  @media (--small-up) {
    font-size: 1.3rem;
    line-height: 1.25;
  }
}

.tile:hover .tile__text,
.tile:focus .tile__text {
  text-decoration: underline;
  text-decoration-thickness: 1px;
}

/**
 * @title Tile Dark
 * @id components/tile/dark
 * @markup ../../styleguide/tile--dark.html
 * @background black
 */

.tile--dark:hover .tile__image,
.tile--dark:focus .tile__image {
  outline-color: var(--color-white);
}

.tile--dark .tile__text {
  color: var(--color-white);
}
