/**
 * @title Checkbox
 * @id components/checkbox
 * @markup ../../styleguide/checkbox.html
 */
.checkbox {
  font-weight: normal;
  position: relative;
  cursor: pointer;
  margin-bottom: 0.5rem;
  text-align: left; /* override inheritance */
}

.checkbox__box {
  position: absolute;
  width: 1px;
  height: 1px;
  clip: rect(1px, 1px, 1px, 1px);
}

.checkbox__text {
  display: inline-block;
  padding-left: 2rem;
}

.checkbox__text::before {
  content: "";
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  left: 0;
  top: 0.2rem;
  margin-right: 0.5rem;
  background: color(var(--color-white) alpha(0.4));
  color: var(--color-black);
  border: 1px solid;
  border-radius: 2px;
  padding: 0.2rem;
}

.checkbox__box:focus + .checkbox__text::before {
  outline: none;
  box-shadow: 0 0 0 1px;
}

.checkbox__text::after {
  width: 0.5rem;
  height: 0.75rem;
  position: absolute;
  left: 0.4rem;
  top: 0.3rem;
  color: var(--color-black);
  border-right: 2px solid;
  border-bottom: 2px solid;
  border-radius: 2px;
  transform: rotate(45deg);
}

.checkbox__box:checked + .checkbox__text::after {
  content: "";
  display: inline-block;
}

/**
 * @title Checkbox - radio
 * @id components/checkbox/radio
 * @markup ../../styleguide/checkbox--radio.html
 */

.checkbox--radio .checkbox__text::before {
  border-radius: 50%;
}

.checkbox--radio .checkbox__text::after {
  transform: none;
  width: 0.6rem;
  height: 0.6rem;
  border: 1px solid;
  background: currentColor;
  border-radius: 50%;
  left: 0.325rem;
  top: 0.525rem;
}

/**
 * @title Radio, link, green
 * @id components/checkbox/radio-link-green
 * @markup ../../styleguide/checkbox--radio-link-green.html
 */
.checkbox--link {
  background: color(var(--color-black) alpha(0.6));
  color: var(--color-white);
  padding: 0.75rem;
  border-radius: 6px;
  display: block;
  border-bottom-style: none;
}

.checkbox--link:hover,
.checkbox--link:focus {
  background: color(var(--color-black) alpha(0.6));
}

.checkbox--link .checkbox__text::before {
  width: 1.75rem;
  height: 1.75rem;
  left: 0.5rem;
  top: 0.75rem;
  border-width: 2px;
  color: var(--color-white);
  background: transparent;
}

.checkbox--link:focus .checkbox__text::before {
  box-shadow: 0 0 0 2px;
}

.checkbox--link .checkbox__text::after {
  width: 1rem;
  height: 1em;
  left: 0.875rem;
  top: 1.125rem;
  color: var(--color-white);
}

.checkbox--green .checkbox__text::before,
.checkbox--green .checkbox__text::after {
  color: var(--color-green);
}

/**
 * @title Radio, link, green, checked
 * @id components/checkbox/radio-link-green-checked
 * @markup ../../styleguide/checkbox--radio-link-green-checked.html
 */
.checkbox--checked .checkbox__text::after {
  content: "";
  display: inline-block;
}

/**
 * @title Radio, link, yellow
 * @id components/checkbox/radio-link-yellow
 * @markup ../../styleguide/checkbox--radio-link-yellow.html
 */
.checkbox--yellow .checkbox__text::before,
.checkbox--yellow .checkbox__text::after {
  color: var(--color-yellow);
}

/**
 * @title Radio, link, red
 * @id components/checkbox/radio-link-red
 * @markup ../../styleguide/checkbox--radio-link-red.html
 */
.checkbox--red .checkbox__text::before,
.checkbox--red .checkbox__text::after {
  color: var(--color-red);
}

/**
 * @title Radio, link, green, dark background
 * @id components/checkbox/radio-link-green-dark
 * @markup ../../styleguide/checkbox--radio-link-green-dark.html
 * @background black
 */
.checkbox--dark:focus {
  outline-color: var(--color-white);
}
