/**
 * @title Button
 * @id components/button
 * @markup ../../styleguide/button.html
 */

.button {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: normal;
  text-decoration: none;
  color: var(--color-white);
  background: var(--color-black);
  border: none;
  display: inline-block;
  padding: 0.5em 1em;
  margin: 0;
  text-align: center;
  border-radius: 2px;
  transition: background-color 0.05s ease-in-out, box-shadow 0.05s ease-in-out;
  box-shadow: 0 0 0 0 var(--color-black); /* Base for transition */
  word-spacing: normal; /* Avoid inheriting, e.g. from cta-box__button-row */
}

.button[hidden] {
  display: none;
}

.button:hover,
.button:focus {
  color: var(--color-white);
  background-color: color(var(--color-black) alpha(0.85));
}

.button:focus {
  outline-offset: 2px;
}

.button:hover {
  outline: none;
  cursor: pointer;
  box-shadow: 0 0 0 2px color(var(--color-black) alpha(0.85));
}

.button:active {
  outline: none;
  opacity: 0.75;
}

/**
 * @title Disabled
 * @id components/button/disabled
 * @markup ../../styleguide/button--disabled.html
 * @width 8
 */
.button:disabled {
  opacity: 0.5;
}

/**
 * @title Button primary
 * @id components/button/primary
 * @markup ../../styleguide/button--primary.html
 * @width 8
 */
.button--primary {
  color: var(--color-black);
  background: var(--color-yellow);
  box-shadow: 0 0 0 0 var(--color-yellow); /* Base for transition */
}

.button--primary:hover,
.button--primary:focus {
  color: var(--color-black);
  background-color: color(var(--color-yellow) alpha(0.85));
}

.button--primary:hover {
  box-shadow: 0 0 0 2px color(var(--color-yellow) alpha(0.85));
}

/**
 * @title Button secondary
 * @id components/button/secondary
 * @markup ../../styleguide/button--secondary.html
 * @width 8
 */
.button--secondary {
  color: var(--color-black);
  background: rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1); /* Base for transition */
}

.button--secondary:hover,
.button--secondary:focus {
  color: var(--color-black);
  background-color: rgba(0, 0, 0, 0.085);
}

.button--secondary:hover {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.085);
}

/**
 * @title Button large
 * @id components/button/large
 * @markup ../../styleguide/button--large.html
 * @width 8
 */
.button--large {
  font: var(--font-h3);
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-block: 17px; /* to give 68px button height at default rem */
}

/**
 * @title Button block
 * @id components/button/block
 * @markup ../../styleguide/button--block.html
 */

.button--block {
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
}

.button--block:last-child {
  margin-bottom: 0;
}

/**
 * @title Button with hovering heart
 * @id components/button/with-hover-heart
 * @markup ../../styleguide/button--hover-heart.html
 * @width 8
 */
.button--hover-heart:hover {
  cursor: url("images/heart-red.png") 16 13, auto;
}

/**
 * @title Icon
 * @id components/button/icon
 * @markup ../../styleguide/button--icon.html
 * @width 8
 */
.button--icon,
.button--icon:hover,
.button--icon:focus {
  background-color: transparent;
  color: inherit;
  box-shadow: none;
}

.button__icon {
  width: 1.5rem;
  height: 1.5rem;
  vertical-align: top;
}

/**
 * @title Dark background
 * @id components/button/dark-background
 * @markup ../../styleguide/button--dark-background.html
 * @width 8
 * @background black
 */
.button--dark-background {
  outline-color: var(--color-white);
}
