/**
 * @title Petition thanks
 * @id components/petition-thanks
 * @markup ../../styleguide/petition-thanks.html
 */
.petition-thanks {
  text-align: center;
}

.petition-thanks__heading {
  font-size: 2rem;
  line-height: 1.25;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: var(--font-wwf);
  font-weight: 700;
  margin: 0 0 1rem;
}

/**
 * @title With count
 * @id components/petition-thanks/with-count
 * @markup ../../styleguide/petition-thanks--with-count.html
 */
.petition-thanks__count-wrapper {
  overflow: hidden;
}

.petition-thanks__count {
  font-size: 4rem;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: var(--font-wwf);
  animation: fade-in-down 300ms ease-out;
}

@media (--medium-up) {
  .petition-thanks__heading {
    margin: 0 0 2rem;
  }
}
