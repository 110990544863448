/**
 * @title Info list
 * @id components/info-list
 * @markup ../../styleguide/info-list.html
 */
.info-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: calc(var(--half-gutter) * -1);
  margin-right: calc(var(--half-gutter) * -1);
}

.info-list__item {
  padding: var(--half-gutter);
  @media (--medium-up) {
    flex-basis: 33.333%;
    max-width: 33.333%;
  }
}

/**
 * @title Floater
 * @id components/info-list/floater
 * @markup ../../styleguide/info-list--floater.html
 */
.info-list--floater {
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 0;
}

.info-list--floater .info-list__item {
  padding-left: 0;
  padding-right: 0;
  flex-basis: 100%;
  max-width: 100%;
  flex: 0 0 auto; /* Needed in IE */
  text-align: center;
  @media (--medium-up) {
    text-align: left;
  }
}

/**
 * @title Half
 * @id components/info-list/half
 * @markup ../../styleguide/info-list--half.html
 * @width imagebox
 */
.info-list--half .info-list__item {
  @media (--medium-up) {
    flex-basis: 50%;
    max-width: 50%;
  }
}

/**
 * @title With large items
 * @id components/info-list/large
 * @markup ../../styleguide/info-list--large.html
 * @width infolist
 */
