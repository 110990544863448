@font-face {
  font-family: "WWFRegular";
  font-weight: 700;
  src: url('/resources/files/wwfregular/wwf-webfont.eot');
  src: url('/resources/files/wwfregular/wwf-webfont.eot#iefix') format("eot"),
    url('/resources/files/wwfregular/wwf-webfont.woff') format("woff"),
    url('/resources/files/wwfregular/wwf-webfont.ttf') format("truetype"),
    url('/resources/files/wwfregular/wwf-webfont.svg#webfontf7UvkYDT')
      format("svg");
  font-weight: 700;
}
