/**
 * @title Glide slider
 * @id components/glide
 * @markup ../../styleguide/glide.html
 */
/* Original Core styles */
.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box; }
  .glide * {
    box-sizing: inherit; }
  .glide__track {
    overflow: hidden; }
  .glide__slides {
    position: relative;
    width: 100%;
    list-style: none;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    touch-action: pan-Y;
    overflow: hidden;
    padding: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    will-change: transform; }
    .glide__slides--dragging {
      user-select: none; }
  .glide__slide {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    white-space: normal;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent; }
    .glide__slide a {
      user-select: none;
      -webkit-user-drag: none;
      -moz-user-select: none;
      -ms-user-select: none; }
  .glide__arrows {
    -webkit-touch-callout: none;
    user-select: none; }
  .glide__bullets {
    -webkit-touch-callout: none;
    user-select: none; }
  .glide--rtl {
    direction: rtl; }

/* Custom */
.glide,
.glide__track,
.glide__slides,
.glide__figure {
  height: 100%;
}

.glide--swipeable {
  cursor: grab;
}

.glide--dragging {
  cursor: grabbing;
}

.glide__figure {
  margin: 0;
  text-align: center;
}

.glide__image {
  max-height: 80%;
}

.glide__caption {
  padding: var(--half-gutter);
  max-height: 20%;
  overflow: auto;
  font: var(--font-body);
}

.glide__previous,
.glide__next {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  color: var(--color-white);
  outline-color: var(--color-white);
}

.glide__previous {
  left: 0;
}

.glide__next {
  right: 0;
}

.glide__previous-icon,
.glide__next-icon {
  width: var(--half-gutter);
  vertical-align: middle;
}

.glide__previous-text,
.glide__next-text {
  display: none;
  vertical-align: middle;
  padding: 0 var(--half-gutter);
}

@media (max-width: 599.99px) and (min-height: 500px) {
  .glide {
    padding-bottom: var(--wide-gutter);
  }

  .glide__previous,
  .glide__next {
    top: auto;
    bottom: 0;
  }

  .glide__previous-text,
  .glide__next-text {
    display: inline;
  }
}

@media (--small-up) {
  .glide__track {
    position: relative;
    z-index: 0;
  }

  .glide__track:before,
  .glide__track:after {
    content: "";
    width: 100px;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
    background: color(var(--color-black) alpha(0.6));
  }

  .glide__track:before {
    left: 0;
  }

  .glide__track:after {
    right: 0;
  }
}
