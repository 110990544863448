@keyframes fade-in-down {
  0% {
    opacity: 0;
    transform: translate3d(0, -50%, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  33% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes green-pulse {
  0% {
    box-shadow: 0 0 0 0px color(var(--color-green) alpha(0.85));
  }
  100% {
    box-shadow: 0 0 0 10px color(var(--color-green) alpha(0));
  }
}

@keyframes orange-pulse {
  0% {
    box-shadow: 0 0 0 0px color(var(--color-orange) alpha(0.85));
  }
  100% {
    box-shadow: 0 0 0 10px color(var(--color-orange) alpha(0));
  }
}

@keyframes red-pulse {
  0% {
    box-shadow: 0 0 0 0px color(var(--color-red) alpha(0.85));
  }
  100% {
    box-shadow: 0 0 0 10px color(var(--color-red) alpha(0));
  }
}

@keyframes big-yellow-pulse {
  0% {
    box-shadow: 0 0 0 0px color(var(--color-yellow) alpha(0.85));
  }
  100% {
    box-shadow: 0 0 0 24px color(var(--color-yellow) alpha(0));
  }
}

@keyframes carousel {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes poll-meter {
  from {
    flex-basis: 50%;
  }
}
