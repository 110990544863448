/**
 * @title Headings
 * @id components/headings
 * @markup ../../styleguide/headings.html
 */
html {
  font-size: 100%; /* 16px */
}

body {
  font-family: var(--font-opensans);
  quotes: "«" "»";
  line-height: 1.5;
  font-weight: 400;
}

h1,
.h1 {
  font-family: var(--font-wwf);
  font-size: 3rem; /* 48px */
  font-weight: 700;
  line-height: 1.125;
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
  margin: 0 0 1rem 0;
  @media (--small-up) {
    font-size: 4rem; /* 64px */
  }
  @media (--large-up) {
    font-size: 6rem; /* 96px */
    line-height: 1;
    letter-spacing: 0.1rem;
  }
}

h2,
.h2 {
  font: var(--font-h2-mobile);
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
  margin: 2rem 0 1rem 0;
  @media (--small-up) {
    margin-top: 4rem;
  }
  @media (--large-up) {
    font: var(--font-h2-desktop);
  }
}

h3,
.h3 {
  font: var(--font-h3);
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
  margin: 2rem 0 1rem 0;
}

h4,
.h4 {
  font-family: var(--font-opensans);
  font-size: 1rem; /* 16px */
  font-weight: 600;
  line-height: 1.25;
  text-transform: none;
  letter-spacing: normal;
  margin: 0 0 1rem 0;
}

p,
.text {
  margin: 0 0 1rem 0;
}

address {
  font-style: normal;
}

a {
  color: inherit;
  transition: all 0.1s ease;
  text-decoration: none;
  border-bottom: 1px solid currentColor;

  &:hover,
  &:focus {
    background-color: var(--color-lightblue);
    border-bottom-width: 1px;
  }

  &:active {
    opacity: 0.75;
    outline: none;
  }
}

ul,
ol {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

blockquote {
  margin: 0;
  margin-bottom: 1rem;
}

blockquote > cite {
  display: block;
  font-size: 0.666rem;
}

img {
  max-width: 100%;
}
