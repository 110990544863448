/**
 * @title Quote
 * @id components/quote
 * @markup ../../styleguide/quote.html
 * @width fullwidth
 */
.quote {
  margin: 0;
}

.quote__text {
  font-size: 2rem;
  line-height: 1.25;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: var(--font-wwf);
  margin-bottom: 0.5rem;
  &:before {
    content: "«";
  }
  &:after {
    content: "»";
  }
}

.quote__text {
  @media (--xsmall-up) {
    font-size: 3rem; /* 48px */
    line-height: 1.125;
  }
  @media (--large-up) {
    font-size: 4.5rem;
    line-height: 1.08;
  }
}

.quote__cite {
  font-size: 0.666rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-style: normal; /* Resets browser default */
}
