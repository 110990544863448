/**
 * @title Info
 * @id components/info
 * @markup ../../styleguide/info.html
 */
.info {
  /* Top-level element has no styles */
}

.info__title {
  font: var(--font-body);
  text-transform: none;
  letter-spacing: normal;
  font-style: italic;
  margin: 0;
  margin-bottom: 0.5rem;
}

.info__value {
  font-size: 2rem;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: var(--font-wwf);
  margin-bottom: 0.5rem;
  @media (--small-up) {
    font-size: 2.25rem;
  }
}

p.info__subtext,
p.info__source {
  margin-bottom: 0.5rem;
}

.info__subtext > p,
.info__source > p {
  margin-bottom: 0.5rem;
}

.info__subtext {
  font: var(--font-body);
}

.info__source {
  color: var(--color-gray);
}

.info__subtext a,
.info__source a {
  border-bottom-color: var(--color-gray);
}

.info__subtext a:hover,
.info__subtext a:focus,
.info__source a:hover,
.info__source a:focus {
  background-color: var(--color-textblack);
  color: var(--color-white);
  border-bottom-color: var(--color-white);
}

.info__value:last-child,
.info__subtext:last-child,
.info__source:last-child {
  margin-bottom: 0;
}

/**
 * @title Large
 * @id components/info/large
 * @markup ../../styleguide/info--large.html
 */
.info--large .info__title {
  font-size: 1.1875rem; /* 19px */
  line-height: 1.36;
  margin-bottom: 0.5rem;
}

.info--large .info__value {
  font-size: 4.5rem; /* 72 px */
  line-height: 1.17;
  @media (--small-up) {
    font-size: 6rem; /* 96px */
    line-height: 1;
  }
}
