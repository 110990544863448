/**
 * @title Seafood category
 * @id components/seafood-category
 * @markup ../../styleguide/seafood-category.html
 */
.seafood-category {
  padding-left: var(--half-gutter);
  border-left: 4px solid;
  margin: var(--wide-gutter) 0;
}

.seafood-category__label {
  display: flex;
}

.seafood-category__label:nth-last-child(n+2) {
  margin-bottom: 1rem;
}

.seafood-category__figure {
  margin: 0 var(--half-gutter) 0 0;
}

.seafood-category__image {
  width: 150px;
  min-width: 100px;
}

.seafood-category__label-text {
  max-width: 20rem;
  margin-bottom: 0;
}

/**
 * @title Green (with ASC)
 * @id components/seafood-category/green
 * @markup ../../styleguide/seafood-category--green.html
 */
/**
 * @title Green (with ASC and MSC)
 * @id components/seafood-category/green-asc-msc
 * @markup ../../styleguide/seafood-category--msc-asc.html
 */
.seafood-category--green {
  border-left-color: var(--color-green);
}

/**
 * @title Yellow
 * @id components/seafood-category/yellow
 * @markup ../../styleguide/seafood-category--yellow.html
 */
.seafood-category--yellow {
  border-left-color: var(--color-yellow);
}

/**
 * @title Red
 * @id components/seafood-category/red
 * @markup ../../styleguide/seafood-category--red.html
 */
.seafood-category--red {
  border-left-color: var(--color-red);
}
