/**
 * @title Garden counter
 * @id components/garden-counter
 * @markup ../../styleguide/garden-counter.html
 */
.garden-counter {
  font-family: var(--font-wwf);
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.25;
  text-transform: uppercase;
  text-align: center;
  color: var(--color-camaronegreen);
}

.garden-counter__area {
  display: block;
  font-size: 3rem;
  letter-spacing: 0.1rem;
  color: var(--color-textblack);
  @media (--small-up) {
    font-size: 4rem;
  }
  @media (--large-up) {
    font-size: 4.5rem;
  }
}

/**
 * @title Dark background
 * @id components/garden-counter/dark-background
 * @markup ../../styleguide/garden-counter--dark-background.html
 * @background black
 */
.garden-counter--dark-background {
  color: var(--color-lightgreen);
}

.garden-counter--dark-background .garden-counter__area {
  color: var(--color-white);
}
