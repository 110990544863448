@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600');
@import "1_variables/**/*.css";
@import "2_base/**/*.css";

@import "flickity.css";

/**
 * @title Layouts
 * @id layouts
 */
@import "3_layout/**/*.css";

/**
 * @title Components
 * @id components
 */
@import "4_components/**/*.css";
@import "5_overrides/**/*.css";
