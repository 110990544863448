/**
 * @title Grid used in forms
 * @id layouts/grid
 * @markup ../../styleguide/grid.html
 */

.l-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: calc(var(--half-gutter) * -1);
  margin-left: calc(var(--half-gutter) * -1);
}

.l-grid__full,
.l-grid__half,
.l-grid__third,
.l-grid__two-thirds,
.l-grid__fifth,
.l-grid__two-fifths,
.l-grid__three-fifths {
  padding: 0 var(--half-gutter);
  flex-basis: 100%;
  max-width: 100%;
}

.l-grid__half {
  @media (--small-up) {
    flex-basis: 50%;
    max-width: 50%;
  }
}

.l-grid__third {
  @media (--small-up) {
    flex-basis: 100%;
    max-width: 100%;
  }
  @media (--medium-up) {
    flex-basis: 33.333%;
    max-width: 33.333%;
  }
}

.l-grid__two-thirds {
  @media (--small-up) {
    flex-basis: 100%;
    max-width: 100%;
  }
  @media (--medium-up) {
    padding: 0 var(--half-gutter);
    flex-basis: 66.666%;
    max-width: 66.666%;
  }
}

.l-grid__fifth {
  flex-basis: 100%;
  max-width: 100%;
  @media (--xsmall-up) {
    flex-basis: 50%;
    max-width: 50%;
  }
  @media (--medium-up) {
    flex-basis: 20%;
    max-width: 20%;
  }
}

.l-grid__two-fifths {
  @media (--small-up) {
    flex-basis: 50%;
    max-width: 50%;
  }
  @media (--medium-up) {
    flex-basis: 40%;
    max-width: 40%;
  }
  @media (--large-up) {
    flex-basis: 40%;
    max-width: 40%;
  }
}

.l-grid__three-fifths {
  @media (--small-up) {
    flex-basis: 50%;
    max-width: 50%;
  }
  @media (--medium-up) {
    flex-basis: 60%;
    max-width: 60%;
  }
  @media (--large-up) {
    flex-basis: 60%;
    max-width: 60%;
  }
}

/**
 * @title Grid used on links
 * @id layouts/grid--related-link
 * @markup ../../styleguide/grid--related-link.html
 * @width fullwidth
 */

.l-grid--stretch {
  @media (--small-up) {
    align-items: stretch;
  }
}

.l-grid--stretch .l-grid__half,
.l-grid--stretch .l-grid__third,
.l-grid--stretch .l-grid__two-thirds,
.l-grid--stretch .l-grid__fifth,
.l-grid--stretch .l-grid__two-fifths,
.l-grid--stretch .l-grid__three-fifths {
  @media (--small-up) {
    display: flex;
    align-items: stretch;
  }
}
