/**
 * @title Link button
 * @id components/link-button
 * @markup ../../styleguide/link-button.html
 */

.link-button {
  color: inherit;
  background: transparent;
  padding: 0;
  transition: all 0.1s ease;
  border-color: currentColor;
  border-width: 1px;
  border-style: none none solid;
}

.link-button:hover,
.link-button:focus {
  color: var(--color-textblack);
  background-color: var(--color-lightblue);
}

.link-button:active {
  opacity: 0.75;
  outline: none;
}
