/**
 * @title Gallery item
 * @id components/gallery-item
 * @markup ../../styleguide/gallery-item.html
 */
.gallery-item {
  margin: 0;
  margin-bottom: var(--gutter);
}

.gallery-item__button {
  border-radius: 9px;
  overflow: hidden;
  width: 100%;
  padding: 0;
  border: none;
  background: transparent;
}

.gallery-item__image {
  width: 100%;
  vertical-align: top;
}
