.l-petition {
  background: var(--color-black);
  color: var(--color-white);
  padding-bottom: var(--gutter);
}

.l-petition__signatures {
  margin-bottom: 2rem;
}

.l-petition__sign-form {
  margin: var(--wide-gutter) 0 var(--gutter);
}

.l-petition__names {
  margin: var(--gutter) 0;
}

@media (--small-up) {
  .l-petition__signatures {
    margin-bottom: 4rem;
  }
}

@media (--medium-up) {
  .l-petition__sign-form,
  .l-petition__names {
    margin-left: calc(-1 * var(--gutter));
    margin-right: calc(-1 * var(--gutter));
  }
}
