/**
 * @title Link list
 * @id components/link-list
 * @markup ../../styleguide/link-list.html
 * @width fullwidth
 * @width wide
 * @background rockgray
 */

/**
 * @title Without meta
 * @id components/link-list/without-meta
 * @markup ../../styleguide/link-list--without-meta.html
 * @width wide
 * @background rockgray
 */

.link-list {

}

.link-list__link {
  display: block;
  position: relative;
  padding: 1rem;
  background: var(--color-white);
  color: var(--color-black);
  border-radius: 9px;
  border: none;
  margin-bottom: var(--half-gutter);
  &:hover,
  &:focus {
    background: var(--color-white);
  }
  @media (--small-up) {
    padding: var(--gutter);
  }
  @media (--large-up) {
    padding-right: 4rem;
  }
}

.link-list__link:after {
  @media (--large-up) {
    content: "";
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-right: 2px solid var(--color-black);
    border-bottom: 2px solid var(--color-black);
    transform: translate(0, -50%) translate(0, 0) rotate(-45deg);
    transition: transform 0.05s ease-in-out;
    position: absolute;
    top: 50%;
    right: 2rem;
  }
}

.link-list__link:hover:after {
  @media (--large-up) {
    transform: translate(0, -50%) translate(4px, 0) rotate(-45deg);
  }
}

.link-list__title {
  margin: 0;
  text-decoration: underline;
  @media (--large-up) {
    text-decoration: none;
  }
}

.link-list__intro {
  font: var(--font-body);
  margin: 1rem 0 0;
}

.link-list__meta {
  font: var(--font-body);
  font-style: italic;
  margin: 1rem 0 0;
}

/**
 * @title Block
 * @id components/link-list/block
 * @markup ../../styleguide/link-list--block.html
 * @width wide
 */
.link-list--block .link-list__link {
  background: var(--color-lightyellow);
  &:hover,
  &:focus {
    background: var(--color-lightyellow);
  }
}

/**
 * For primary navigation on list pages
 *
 * @title Dark background, primary
 * @id components/link-list/dark-background
 * @markup ../../styleguide/link-list--dark-background.html
 * @background black
 * @width wide
 */
.link-list--dark-background .link-list__link {
  outline-color: var(--color-white);
}

.link-list--primary .link-list__title {
  @media (--large-up) {
    font: var(--font-h2-desktop);
  }
}
