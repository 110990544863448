/* The reason for having possibly multiple backdrops per page, and for the
 * selectors occasionally triggering off aria attributes, is that this is
 * basically what we have to work with when using the a11y-dialog library.
 */
.l-dialog[aria-hidden="true"] {
  display: none;
}

/* This declaration block is duplicated since browsers not supporting
 * ::backdrop will drop the entire block (as per the CSS spec). When
 * dialog is well supported, l-dialog__backdrop can be removed
 */
.l-dialog__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}
.l-dialog__box::backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}

.l-dialog[data-a11y-dialog-native] .l-dialog__backdrop {
  display: none;
}

.l-dialog__box {
  position: fixed;
  top: 50%;
  left: 50%;
  margin: 0;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: calc(var(--grid-width) + 2 * var(--wide-gutter));
  max-height: 100%;
  overflow: auto;
  background: var(--color-white);
  border: none;
  border-radius: 9px;
  padding: var(--wide-gutter) 0 0;
  display: none;
}

.l-dialog__box[open] {
  display: block;
}

.l-dialog--gallery .l-dialog__box {
  max-width: none;
  background: var(--color-black);
  color: var(--color-white);
  border-radius: 0;
  height: 100%;
}

.l-dialog__close {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.l-dialog__section {
  padding: 0 var(--half-gutter) var(--half-gutter);
}

.l-dialog__section--form {
  padding-bottom: 0;
  background-color: var(--color-yellow);
}

.l-dialog__section--footer {
  padding-top: var(--half-gutter);
  background-color: var(--color-black);
  color: var(--color-white);
}

@media (--small-up) {
  .l-dialog__section {
    padding: 0 var(--gutter) var(--gutter);
  }

  .l-dialog__section--form {
    padding: 0;
  }
}

@media (--medium-up) {
  .l-dialog__box {
    padding: var(--wide-gutter) 0 0;
  }

  .l-dialog__section {
    padding: 0 var(--wide-gutter) var(--wide-gutter);
  }

  .l-dialog__section--form {
    padding: 0 var(--gutter);
  }

  .l-dialog__section--footer {
    padding-top: var(--wide-gutter);
  }
}
