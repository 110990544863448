/**
 * @title Name carousel
 * @id components/name-carousel
 * @markup ../../styleguide/name-carousel.html
 * @background black
 */
.name-carousel__carousel-wrapper {
  display: flex;
}

.name-carousel__list-wrapper {
  position: relative;
  z-index: 0;
  overflow: hidden;
  flex: auto;
}

.name-carousel__list-wrapper:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: var(--gutter);
  background: linear-gradient(to right, black 0%, transparent 100%);
  z-index: 1;
}

.name-carousel__list-wrapper:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: var(--gutter);
  background: linear-gradient(to left, black 0%, transparent 100%);
  z-index: 1;
}

.name-carousel__list {
  opacity: 0.75;
  white-space: nowrap;
  display: inline-block;
  animation: carousel 30s linear infinite;
  animation-play-state: paused;
  padding-left: 100%;
}

.name-carousel__list.isPlaying {
  animation-play-state: running;
}

.name-carousel__name {
  display: inline-block;
}

.name-carousel__name:nth-child(n+2) {
  margin-left: var(--gutter);
}

.name-carousel__pause {
  flex: none;
  border: none;
  padding: 0;
  margin-left: var(--half-gutter);
  background: transparent;
  color: inherit;
  font-size: 0.625rem;
  line-height: 1.344;
  text-transform: uppercase;
  letter-spacing: 1px;
  outline-color: currentColor;
  @media (--large-up) {
    font-size: 0.56rem;
    line-height: 1.5;
  }
}

.name-carousel__icon {
  display: inline-block;
  padding: calc(0.25 * var(--half-gutter));
  background: var(--color-white);
  position: relative;
  font-size: 0px;
  border-radius: 50%;
}

.name-carousel__icon svg {
  width: calc(0.5 * var(--half-gutter));
  height: calc(0.5 * var(--half-gutter));
  vertical-align: baseline;
  fill: var(--color-darkgray);
}

.name-carousel__summary {
  flex: none;
  width: 100%;
  text-align: center;
  margin-top: 0.5rem;
}
