/**
 * @title Goal progress
 * @id components/goal-progress
 * @markup ../../styleguide/goal-progress.html
 */
/**
 * @title Empty
 * @id components/goal-progress/empty
 * @markup ../../styleguide/goal-progress--empty.html
 */
/**
 * @title Full
 * @id components/goal-progress/full
 * @markup ../../styleguide/goal-progress--full.html
 */
/**
 * @title Exceeding
 * @id components/goal-progress/exceeding
 * @markup ../../styleguide/goal-progress--exceeding.html
 */
.goal-progress {
  padding-bottom: 2rem;
}

.goal-progress__definitions {
  margin: 0;
  display: flex;
  justify-content: space-between;
}

.goal-progress__definition {
  text-align: center;
}

.goal-progress__value {
  margin: 0;
  font-size: 2.25rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: var(--font-wwf);
  font-weight: 700;
}

.goal-progress__meter {
  height: 1.5em;
  background: var(--color-darkred);
}

.goal-progress__meter-filled {
  height: 100%;
  background: var(--color-red);
  position: relative;
  text-align: right;
}

.goal-progress__meter-filled:before {
  content: "";
  position: absolute;
  top: -10px;
  right: -4px;
  width: 10px;
  height: 10px;
  background: inline("images/heart-red.svg") top center no-repeat;
  background-size: contain;
}

.goal-progress__meter-filled:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 1.75rem;
  border-right: 2px solid var(--color-white);
}

.goal-progress__meter-value {
  position: relative;
  top: 2rem;
}
