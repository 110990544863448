/**
 * @title Layout - Module-page
 * @id layouts/page-support
 * @markup ../../styleguide/page-support.html
 * @width fullwidth
 */

/**
 * @title Layout - Module-page 2
 * @id layouts/page-wolf
 * @markup ../../styleguide/page-wolf.html
 * @width fullwidth
 */

/**
 * @title Layout - Campaign page
 * @id layouts/page-wolf-suing
 * @markup ../../styleguide/page-wolf-suing.html
 * @width fullwidth
 */

/**
 * @title Layout - Campaign page 2
 * @id layouts/page-wolf-suing2
 * @markup ../../styleguide/page-wolf-suing2.html
 * @width fullwidth
 */

/**
 * @title Layout - Form thank you
 * @id layouts/page-wolf-thank-you
 * @markup ../../styleguide/page-wolf-thank-you.html
 * @width fullwidth
 */

/**
 * @title Layout - Guardian page
 * @id layouts/page-guardian
 * @markup ../../styleguide/page-guardian.html
 * @width fullwidth
 */

/**
 * @title Layout - News-article
 * @id layouts/page-news-article
 * @markup ../../styleguide/page-news-article.html
 * @width fullwidth
 */

/**
 * @title Layout - Frontpage
 * @id layouts/page-frontpage
 * @markup ../../styleguide/page-frontpage.html
 * @width fullwidth
 */

/**
 * @title Layout - Frontpage Light
 * @id layouts/page-frontpage/light
 * @markup ../../styleguide/page-frontpage-light.html
 * @width fullwidth
 */

/**
 * @title Layout - Animal page
 * @id layouts/page-animal
 * @markup ../../styleguide/page-animal.html
 * @width fullwidth
 */

/**
 * @title Layout - Animals page
 * @id layouts/page-animals
 * @markup ../../styleguide/page-animals.html
 * @width fullwidth
 */

/**
 * @title Layout - Pandaclub
 * @id layouts/page-pandaclub
 * @markup ../../styleguide/page-pandaclub.html
 * @width fullwidth
 */

.l-wrapper {
  display: block;
  padding: 0 var(--half-gutter);
  width: 100%; /* needed when child of flex containers */
  margin: 0 auto;
  @media (--large-up) {
    max-width: var(--grid-width-desktop);
  }
}

.l-wrapper--full {
  max-width: none;
  margin: 0;
  padding: 0;
}

.l-wrapper--space {
  margin-top: var(--gutter);
  margin-bottom: var(--gutter);
  @media (--small-up) {
    margin-top: var(--wide-gutter);
    margin-bottom: var(--wide-gutter);
  }
}

/* Clearfix for all floating elements */
.l-wrapper:before {
  content: "";
  display: table;
  clear: both;
}

/* Avoid clearfix for floating elements that are intended to float into next wrapper for instance .l-floater */
.l-wrapper--accept-floating:before {
  display: none;
  clear: none;
}

.page > .l-wrapper:last-child {
  margin-bottom: calc(2 * var(--wide-gutter));
}

.section + .l-wrapper {
  margin-top: var(--wide-gutter);
}

.l-wrapper__contents {
  max-width: var(--grid-width-tablet);
  margin: 0 auto;
  @media (--small-up) {
    padding: 0 var(--half-gutter);
  }
  @media (--large-up) {
    max-width: none;
    margin-left: calc(2 / 16 * 100%);
    margin-right: 0;
    width: calc(8 / 16 * 100%);
  }
  @media (--xlarge-up) {
    width: calc(7 / 16 * 100%);
  }
}

.l-wrapper__contents--narrow {
  max-width: var(--grid-half);
}


.l-wrapper__contents--wide {
  @media (--large-up) {
    width: calc(9 / 16 * 100%);
  }
  @media (--xlarge-up) {
    width: calc(8 / 16 * 100%);
  }
}

.l-wrapper__contents--xwide {
  @media (--large-up) {
    width: calc(10 / 16 * 100%);
  }
  @media (--xlarge-up) {
    width: calc(9 / 16 * 100%);
  }
}

.l-wrapper__contents--xxwide {
  @media (--large-up) {
    margin-left: calc(1 / 16 * 100%);
    width: calc(10 / 16 * 100%);
  }
}

/* The following 'contents' variants are not intended to _only_ be used by the
 * blocks referred to, those are merely chosen as "representative" examples.
 * E.g. l-wrapper__contents--imagebox should be interpreted as "this should be
 * positioned the same way as an 'image-box'.
 *
 * The variants above could be renamed using the same approach as well, as
 * opportunity arises. Representative examples: default = body, wide = nav,
 * xwide = lede, xxwide = quote
 */
.l-wrapper__contents--infolist {
  max-width: none;
  @media (--large-up) {
    width: calc(12 / 16 * 100%);
    margin-left: calc(2 / 16 * 100%);
  }
}

.l-wrapper__contents--imagebox {
  width: 100%;
  max-width: none;
  margin-left: 0;
  @media (--xlarge-up) {
    width: calc(14 / 16 * 100%);
    margin-left: calc(1 / 16 * 100%);
  }
}
