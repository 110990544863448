/**
 * @title Section
 * @id components/section
 * @markup ../../styleguide/section.html
 * @width fullwidth
 */
.section {
  padding: var(--gutter) 0;
  background: var(--color-rockgray);
  clear: both;
  @media (--small-up) {
    padding: var(--wide-gutter) 0;
  }
}

.section__title {
  text-align: center;
  margin: 0 0 var(--gutter) 0;
  @media (--small-up) {
    margin-bottom: var(--wide-gutter);
  }
}

/**
 * @title Dark color theme
 * @id components/section/dark
 * @markup ../../styleguide/section--dark.html
 * @width fullwidth
 */

.section--dark {
  background: var(--color-black);
  color: var(--color-white);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.section--dark *:focus {
  outline-color: var(--color-white);
}

/**
 * @title Desert color theme
 * @id components/section/desert
 * @markup ../../styleguide/section--desert.html
 * @width fullwidth
 */
.section--desert {
  background: var(--color-darkbrown);
  color: var(--color-white);
}

/**
 * @title Ocean color theme
 * @id components/section/ocean
 * @markup ../../styleguide/section--ocean.html
 * @width fullwidth
 */
.section--ocean {
  background: var(--color-darkblue);
  color: var(--color-white);
}

/**
 * @title Garden color theme
 * @id components/section/garden
 * @markup ../../styleguide/section--garden.html
 * @width fullwidth
 */
.section--garden {
  background: var(--color-darkgreen);
  color: var(--color-white);
}

/**
 * @title Section, tight
 * @id components/section/tight
 * @markup ../../styleguide/section--tight.html
 * @width fullwidth
 */

.section--tight {
  padding: var(--gutter) 0;
}

.l-wrapper + .section {
  margin-top: var(--wide-gutter);
}

.page > .section:last-child {
  border-bottom: 1px solid var(--color-darkbeige);
}
