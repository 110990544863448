/**
 * @title Secondary search
 * @id components/secondary-search
 * @markup ../../styleguide/secondary-search.html
 * @background black
 */
.secondary-search {
  @media (--small-up) {
    width: 12rem;
  }
}

.secondary-search__wrapper {
  position: relative;
  z-index: 0;
  width: 100%;
  @media (--small-up) {
    transition: width 200ms ease-in-out;
  }
}

.secondary-search.isCollapsed .secondary-search__wrapper {
  @media (--small-up) {
    width: 6rem;
    transition: none;
  }
}

.secondary-search__expand {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 1rem;
  padding: 0 1rem;
  color: var(--color-white);
  background: transparent;
  text-align: left;
}

.secondary-search__input {
  -webkit-appearance: textfield; /* hack to make Safari/macOS apply our CSS */
  background: transparent;
  color: var(--color-white);
  border: 1px solid var(--color-white);
  padding: 0.5rem 3rem 0.5rem 1rem;
  border-radius: 1.25rem;
  width: 100%;
}

.secondary-search__expand:focus,
.secondary-search__input:focus {
  outline: none;
  box-shadow: 0 0 0 1px var(--color-white);
}

.secondary-search__submit {
  position: absolute;
  top: 0;
  right: 0.25rem;
  line-height: 1;
  color: var(--color-white);
  background: transparent;
  border: none;
  padding: 0.75rem;
  outline-color: var(--color-white);
}

.secondary-search__icon {
  width: 1rem;
  height: 1rem;
}
