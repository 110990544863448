/**
 * @title Seafood top
 * @id components/seafood-top
 * @markup ../../styleguide/seafood-top.html
 * @width fullwidth
 */
.seafood-top {
  background: var(--color-beige);
  padding: var(--gutter) 0;
}

.seafood-top__figure {
  margin: var(--gutter) 0;
}

.seafood-top__image {
  vertical-align: top;
}

.seafood-top__title {
  margin-top: var(--half-gutter);
}

.seafood-top__meta {
  font-style: italic;
  margin-bottom: var(--gutter);
}

.seafood-top__intro {
  font: var(--font-intro-mobile);
}

@media (--small-up) {
  .seafood-top__intro {
    font: var(--font-intro-desktop);
  }
}
