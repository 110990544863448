/**
 * @title Main menu
 * @id components/main-menu
 * @markup ../../styleguide/main-menu.html
 */
.main-menu {
  background: var(--color-black);
  @media (--small-up) {
    display: flex;
    flex-wrap: wrap;
  }
}

.main-menu__link {
  display: inline-block;
  color: var(--color-white);
  padding: var(--half-gutter);
  position: relative;
  border: none;
  font: 700 2.25rem var(--font-wwf);
  letter-spacing: 0.09rem;
  text-transform: uppercase;
  @media (--small-up) {
    display: block;
  }
  @media (--large-up) {
    font-size: 1.5rem;
    letter-spacing: 0.0625rem;
  }
  &:hover, &:focus {
    background: none;
    border: none;
  }
  &:focus {
    outline-color: var(--color-white);
  }
  &:active {
    outline: none;
  }
}

.main-menu__item:first-child .main-menu__link {
  color: var(--color-orange);
}

.main-menu__link:after {
  content: '';
  position: absolute;
  bottom: 0.5rem;
  left: var(--half-gutter);
  right: var(--half-gutter);
  border-bottom: 3px solid transparent;
  transition: border-bottom-color 100ms ease-out;
}

.main-menu__link:focus:after,
.main-menu__link:hover:after,
.main-menu__link--current:after,
.main-menu:hover .main-menu__link--current:hover:after,
.main-menu:hover .main-menu__link--current:focus:after {
  border-bottom-color: var(--color-orange);
}

/* Hide border on current when hovering other menu items in menu */
.main-menu:hover .main-menu__link--current:after {
  border-bottom-color: transparent;
}
