/**
 * @title Boilerplate
 * @id components/boilerplate
 * @markup ../../styleguide/boilerplate.html
 * @width wide
 */
.boilerplate {
  margin-bottom: var(--wide-gutter);
}

.boilerplate__figure {
  width: 100%;
  max-width: 6.25rem;
  margin: 0 auto;
  padding-bottom: var(--half-gutter);
}

.boilerplate__vision {
  font-size: 1.875rem; /* 30px */
  line-height: 1.27;
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
  font-family: var(--font-wwf);
  font-weight: 700;
  text-align: center;

  @media (--large-up) {
    font-size: 2.25rem; /* 36px */
    line-height: 1.25;
  }
}

/**
 * @title Statement
 * @id components/boilerplate/statement
 * @markup ../../styleguide/boilerplate--statement.html
 */
.boilerplate--statement {
  margin: 0;
}
