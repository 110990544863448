/**
 * @title Highlighted download
 * @id components/highlighted-download
 * @markup ../../styleguide/highlighted-download.html
 * @width fullwidth
 */
.highlighted-download {
  padding: 6rem var(--half-gutter) 8rem;
  background-size: cover;
  margin: var(--wide-gutter) 0;
}

.highlighted-download__text {
  background: var(--color-white);
  padding: 2rem 1rem;
  max-width: 40rem;
  margin-right: 0;
  margin-left: auto;
}

.highlighted-download__label {
  font: var(--font-body);
}

.highlighted-download__heading {
  margin: 0;
}

.highlighted-download__strapline {
  font: var(--font-body);
  font-style: italic;
}

.highlighted-download__lead {
  font: var(--font-body);
}

.highlighted-download__link {
  margin: 2rem -0.5rem -6rem;
  max-width: 30rem;
}

@media (--small-up) {
  .highlighted-download__text {
    padding: 2rem;
  }

  .highlighted-download__link {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (--medium-up) {
  .highlighted-download {
    padding: 8rem 6rem 8rem;
  }
}
