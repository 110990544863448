/**
 * @title Form group
 * @id components/form-group
 * @markup ../../styleguide/form-group.html
 */

.form-group {
  margin-bottom: var(--gutter);
}

.form-group__legend {
  font-size: 1.3rem; /* 20.8px */
  font-weight: 600;
  line-height: 1.25;
  margin: 2rem 0 1rem 0;
}
