/**
 * @title Radio filters
 * @id components/radio-filters
 * @markup ../../styleguide/radio-filters.html
 */
.radio-filters__legend {
  font-family: var(--font-opensans);
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: normal;
  text-transform: none;
  margin: 0 0 0.5rem;
}

.radio-filters__filter {
  margin-bottom: 0.75rem;
  position: relative;
}

.radio-filters__reset {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background: none;
  border: none;
  padding: 0 0.5rem;
  outline-color: currentColor;
}

.radio-filters__reset:hover,
.radio-filters__reset:focus {
  background: none;
}

.radio-filters__reset-icon {
  width: 1rem;
  height: 100%;
}
