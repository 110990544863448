/**
 * @title Floater
 * @id layouts/floater
 * @markup ../../styleguide/floater.html
 * @width fullwidth
 */
.l-floater {
  margin: var(--wide-gutter) auto;
  max-width: var(--grid-width-tablet);
  @media (--small-up) {
    padding: 0 var(--half-gutter);
  }
  @media (--medium-up) {
    float: right;
    max-width: var(--grid-half);
    padding: 0;
    margin: var(--gutter) var(--gutter) var(--gutter) var(--wide-gutter);
  }
  @media (--large-up) {
    max-width: none;
    width: calc(4 / 16 * 100%);
    margin-right: calc(1 / 16 * 100%);
    padding: 0 var(--half-gutter);
  }
}
