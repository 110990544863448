/**
 * @title Story
 * @id components/story
 * @markup ../../styleguide/story.html
 * @width fullwidth
 */
.story {
  position: relative;
  z-index: 0;
  clear: both;
  margin: 4rem 0;
}

.story__card {
  position: relative;
  z-index: 0;
  transition: opacity 0.5s;
  color: var(--color-textblack);
  background: var(--color-lightyellow);
}

.story__card--scrolling {
  z-index: 1;
}

.story__card--scrolling + .story__card--sticky {
  margin-top: -100vh;
  padding-top: 100vh;
}

.story__card--sticky + .story__card {
  margin-top: -100vh;
}

.story__card--sticky::after {
  content: "";
  display: block;
  height: 200vh;
}

.story.is-story-scripted .story__card--sticky + .story__card--sticky {
  opacity: 0;
  margin-top: -200vh;
}

.story.is-story-scripted .story__card--sticky + .story__card--sticky.is-active {
  opacity: 1;
  z-index: 1;
}

.story__card:last-child::after {
  height: 100vh;
}

.story__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Safari on iOS seems to have drawing bugs if we set this to 0 */
  z-index: -1;
}

.story__figure {
  margin: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.story__card--sticky .story__figure {
  position: sticky;
  height: 100vh;
}

.story__image {
  width: 100%;
  height: 100%;
  vertical-align: top;
  object-fit: cover;
}

.story__copyright {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  color: var(--color-black);
  background: var(--color-white);
  padding: 4px 10px 6px;
  font-size: 0.5rem;
  line-height: 1.68;
  letter-spacing: 1px;
  text-transform: uppercase;
  @media (--small-up) {
    bottom: 30px;
    left: 30px;
    margin-right: 30px;
  }
}

.story__text-contents {
  position: relative;
  z-index: 1;
  /* Not setting vertical margin directly on fixed-text etc, because of margin
   * collapsing with story__card
   */
  padding-top: 100vh;
}

.story__text-contents::after {
  content: "";
  display: block;
  clear: left;
}

.story__paragraph {
  font: 1.25rem/1.38 var(--font-georgia);
  float: left;
  clear: left;
  padding: 1rem;
  margin-bottom: 0.5rem;
  @media (--small-up) {
    font-size: 1.75rem;
  }
}

.story__large-text {
  margin-bottom: 0.5rem;
  font: 3rem/1.3 var(--font-wwf);
  text-transform: uppercase;
  overflow-wrap: break-word;
  @media (--xsmall-up) {
    font-size: 4.5rem;
  }
  @media (--large-up) {
    font-size: 6rem;
  }
}

.story__large-text-contents {
  display: inline;
  padding: 0 1rem;
  box-decoration-break: clone;
}

.story__quote-source {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font: var(--font-body);
  font-style: italic;
}

.story__fixed-text-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.story__fixed-text {
  padding: 1rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.story__card--image .story__paragraph,
.story__card--image .story__large-text-contents,
.story__card--image .story__quote-source,
.story__card--image .story__fixed-text {
  color: var(--color-textblack);
  background: var(--color-white);
}

.story__fixed-heading {
  margin: 0;
  font-family: var(--font-wwf);
  font-weight: 700;
  font-size: 3rem;
  line-height: 1.2;
  text-transform: uppercase;
  @media (--small-up) {
    font-size: 4rem;
  }
  @media (--large-up) {
    font-size: 5rem;
  }
}

.story__fixed-body {
  margin-top: 2rem;
  max-width: 45rem;
}

.story__fixed-body,
.story__fixed-body p {
  font-size: 1.25rem;
}

/**
 * @title With title card (default color)
 * @id components/story/title
 * @markup ../../styleguide/story--title.html
 * @width fullwidth
 */
.story--title {
  margin-top: 0;
}

.story__top {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem 0;
}

.story__top-eyebrow {
  margin-bottom: 0.5rem;
  padding: 0.5rem 1rem;
  display: inline-block;
  background: var(--color-lightyellow);
  font: 700 1.625rem/1.15 var(--font-wwf);
  letter-spacing: 1px;
  text-transform: uppercase;
  @media (--large-up) {
    font-size: 2.625rem;
    line-height: 1.07;
  }
}

.story__top-heading {
  overflow-wrap: break-word;
  line-height: 1.3;
  margin-bottom: 0.5rem;
  @media (--xsmall-up) {
    font-size: 6.25rem;
  }
  @media (--large-up) {
    font-size: 12.5rem;
  }
}

.story__top-heading-text {
  padding: 0 1rem;
  background: var(--color-lightyellow);
  display: inline;
  box-decoration-break: clone;
}

.story__top-intro {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background: var(--color-lightyellow);
  font: 1.5rem/1.25 var(--font-georgia);
  max-width: 71rem;
  @media (--large-up) {
    font-size: 1.875rem;
    line-height: 1.27;
  }
}

.story__top-published {
  padding: 0.5rem 1rem;
  font: italic 1rem/1.25 var(--font-opensans);
  margin: 1rem 0 0;
  width: fit-content;
  @media (--large-up) {
    font-size: 1.2rem;
  }
}

/**
 * @title Cold neutral (with title card)
 * @id components/story/neutral-cold
 * @markup ../../styleguide/story--neutral-cold.html
 * @width fullwidth
 */
.story--neutralCold .story__card,
.story--neutralCold .story__top-eyebrow,
.story--neutralCold .story__top-heading-text,
.story--neutralCold .story__top-intro,
.story--neutralCold .story__top-published {
  background: var(--color-white);
}

/**
 * @title Dramatic (with title card)
 * @id components/story/dramatic
 * @markup ../../styleguide/story--dramatic.html
 * @width fullwidth
 */
.story--dramatic .story__card,
.story--dramatic .story__top-eyebrow,
.story--dramatic .story__top-heading-text,
.story--dramatic .story__top-intro,
.story--dramatic .story__top-published {
  color: var(--color-white);
  background: var(--color-black);
}

/**
 * @title Nature (with title card)
 * @id components/story/nature
 * @markup ../../styleguide/story--nature.html
 * @width fullwidth
 */
.story--nature .story__card,
.story--nature .story__top-eyebrow,
.story--nature .story__top-heading-text,
.story--nature .story__top-intro,
.story--nature .story__top-published {
  color: var(--color-white);
  background: var(--color-darkgreen);
}

/**
 * @title Ocean (with title card)
 * @id components/story/ocean
 * @markup ../../styleguide/story--ocean.html
 * @width fullwidth
 */
.story--ocean .story__card,
.story--ocean .story__top-eyebrow,
.story--ocean .story__top-heading-text,
.story--ocean .story__top-intro,
.story--ocean .story__top-published {
  color: var(--color-white);
  background: var(--color-darkblue);
}

/**
 * @title Desert (with title card and published date)
 * @id components/story/desert
 * @markup ../../styleguide/story--desert.html
 * @width fullwidth
 */
.story--desert .story__card,
.story--desert .story__top-eyebrow,
.story--desert .story__top-heading-text,
.story--desert .story__top-intro,
.story--desert .story__top-published {
  color: var(--color-white);
  background: var(--color-darkbrown);
}

.story--dramatic .story__copyright,
.story--nature .story__copyright,
.story--ocean .story__copyright,
.story--desert .story__copyright {
  color: var(--color-white);
  background: var(--color-black);
}
