/**
 * @title Tiles
 * @id components/tiles
 * @markup ../../styleguide/tiles.html
 * @width fullwidth
 */
.tiles {
  margin-bottom: var(--gutter);
  padding-top: var(--half-gutter);
}

.tiles + .tiles {
  margin-top: calc(-1 * var(--gutter));
}

.tiles__title {
  margin: 0 0 var(--gutter) 0;
  font: var(--font-h3);
  @media (--small-up) {
    margin-top: var(--half-gutter);
  }
  @media (--large-up) {
    font: var(--font-h2-desktop);
  }
}

.tiles__list {
  @media (--xsmall-up) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 calc(-0.5 * var(--half-gutter));
  }
  @media (--small-up) {
    margin: 0 calc(-1 * var(--half-gutter));
  }
}

.tiles__list-item {
  margin-bottom: var(--gutter);
  @media (--xsmall-up) {
    margin-bottom: 0;
    padding: calc(0.5 * var(--half-gutter));
    flex-basis: 50%;
    max-width: 50%;
  }
  @media (--small-up) {
    padding: var(--half-gutter);
  }
  @media (--medium-up) {
    flex-basis: 25%;
    max-width: 25%;
  }
}

/**
 * @title Tiles Dark
 * @id components/tiles/dark
 * @markup ../../styleguide/tiles--dark.html
 * @width fullwidth
 */

.tiles--dark {
  padding-bottom: var(--half-gutter);
  background: var(--color-black);
  @media (--small-up) {
    padding-bottom: var(--gutter);
  }
}

.tiles--dark .tiles__title {
  color: var(--color-white);
}

/**
 * @title Tiles with title
 * @id components/tiles/title
 * @markup ../../styleguide/tiles--with-title.html
 * @width fullwidth
 */
