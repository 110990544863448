/**
 * @title Newsletter form
 * @id components/newsletter-form
 * @markup ../../styleguide/newsletter-form.html
 */
.newsletter-form {
  max-width: 30rem;
  margin: 0 auto var(--wide-gutter);
}

.newsletter-form__intro,
.newsletter-form__submit {
  text-align: center;
}
