/**
 * @title Call to action box
 * @id components/cta-box
 * @markup ../../styleguide/cta-box.html
 */

/**
 * @title Share signature
 * @id components/cta-box/share-signature
 * @markup ../../styleguide/cta-box--share-signature.html
 */

.cta-box {
  /* Top-level element has no styles */
}

.cta-box__block {
  padding: var(--gutter) var(--half-gutter);
  background: var(--color-yellow);
  margin: 0 calc(-1 * var(--half-gutter));
  color: var(--color-black);
  @media (--small-up) {
    margin-left: 0;
    margin-right: 0;
    border-radius: 9px;
    padding-left: var(--gutter);
    padding-right: var(--gutter);
  }
}

/**
 * @title With form
 * @id components/cta-box/form
 * @markup ../../styleguide/cta-box--form.html
 */

/**
 * @title Gift form
 * @id components/cta-box/gift
 * @markup ../../styleguide/cta-box--gift.html
 */
.cta-box--form .cta-box__block {
  padding: var(--gutter);
  @media (--large-up) {
    padding: 3.75rem;
  }
}

.cta-box__title {
  font-family: var(--font-wwf);
  font-size: 1.875rem; /* 30px */
  line-height: 1.27;
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
  margin-top: 0;
  @media (--large-up) {
    font-size: 2.25rem; /* 36px */
    line-height: 1.25;
  }
}

.form-field--inline .cta-box__title {
  margin-bottom: 0;
}

/**
 * @title Secondary, with form
 * @id components/cta-box/secondary-form
 * @markup ../../styleguide/cta-box--secondary-form.html
 */

/**
 * @title Secondary, Become Guardian form
 * @id components/cta-box/secondary-guardian
 * @markup ../../styleguide/cta-box--secondary-guardian.html
 */

/**
 * @title Secondary, Pandaclub member form
 * @id components/cta-box/secondary-pandaclub
 * @markup ../../styleguide/cta-box--secondary-pandaclub.html
 */

/**
 * @title Did you find what you where looking for?
 * @id components/cta-box/did-you-find
 * @markup ../../styleguide/cta-box--secondary-did-you-find.html
 */
.cta-box--secondary .cta-box__block {
  background: var(--color-beige);
  color: var(--color-textblack);
  animation: none;
}

.cta-box__logo {
  width: 3.25rem;
  margin: 0 auto;
  margin-bottom: 2rem;
  @media (--large-up) {
    width: 5rem;
  }
}

.cta-box__centered {
  text-align: center;
}

.cta-box__suffix-links {
  margin-top: 1rem;
  @media (--small-up) {
    padding: 0 var(--gutter);
  }
}

/**
 * @title Pulse animation
 * @id components/cta-box/pulse
 * @markup ../../styleguide/cta-box--pulse.html
 */

.cta-box--pulse .cta-box__block {
  animation: infinite big-yellow-pulse 1300ms ease-out;
}

/* Only used in Image Box component */

.cta-box--collapsed .cta-box__block {
  padding: 0;
  margin: var(--half-gutter) 0 var(--wide-gutter) 0;
  background: none;
}

.cta-box--collapsed .cta-box__centered {
  text-align: left;
}

.cta-box--borderless .cta-box__block {
  border-radius: 0;
  margin: 0;
}

/**
 * @title Secondary, gift form
 * @id components/cta-box/secondary-gift
 * @markup ../../styleguide/cta-box--secondary-gift.html
 */

.cta-box--child .cta-box__block {
  background: color(var(--color-white) alpha(0.4));
  border-radius: 0;
  margin-bottom: var(--half-gutter);
}

.cta-box--child .cta-box__title {
  font-family: var(--font-opensans);
  font-size: 1.3rem; /* 20.8px */
  font-weight: 600;
  line-height: 1.25;
  text-transform: none;
  letter-spacing: normal;
}

/**
 * @title Secondary, primer form
 * @id components/cta-box/secondary-primer
 * @markup ../../styleguide/cta-box--secondary-primer.html
 * @width fullwidth
 */

.cta-box__buttons {
  margin-top: var(--gutter);
}


/**
 * @title Secondary, member form
 * @id components/cta-box/secondary-member
 * @markup ../../styleguide/cta-box--secondary-member.html
 */

/**
 * @title Secondary, member gift form
 * @id components/cta-box/secondary-member-gift
 * @markup ../../styleguide/cta-box--secondary-member-gift.html
 */
@media (--small-up) {
  .cta-box__line {
    margin: 0 calc(var(--half-gutter) * -1);
  }

  .cta-box__line-item {
    display: inline-block;
    padding: 0 var(--half-gutter);
  }
}

/**
 * @title Extraordinary
 * @id components/cta-box/extraordinary
 * @markup ../../styleguide/cta-box--extraordinary.html
 */
.cta-box--extraordinary .cta-box__block {
  margin-left: 0;
  margin-right: 0;
  border-radius: 9px;
}

/**
 * @title Secondary, share
 * @id components/cta-box/secondary-share
 * @markup ../../styleguide/cta-box--secondary-share.html
 */
.cta-box__share-message {
  max-width: 25rem;
  margin: 0 auto 1rem;
}

.cta-box__button-row {
  display: block;
  text-align: center;
  word-spacing: 0.5rem;
  margin: 1rem 0;
}
