/**
 * @title Headline number
 * @id components/headline-number
 * @markup ../../styleguide/headline-number.html
 */
.headline-number {
  text-align: center;
}

.headline-number__value {
  display: block;
  font-size: 4rem;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: var(--font-wwf);
}

@media (--small-up) {
  .headline-number__value {
    font-size: 6rem;
  }
}

@media (--medium-up) {
  .headline-number__value {
    font-size: 7rem;
  }
}
