/**
 * @title Person
 * @id components/person
 * @markup ../../styleguide/person.html
 */
.person {
  margin-top: var(--gutter);
  margin-bottom: var(--gutter);
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (--small-up) {
    flex-direction: row;
  }
}

.person__image-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: var(--half-gutter);
  width: 66%;
  max-width: 14rem;
  @media (--small-up) {
    width: 100%;
    margin-bottom: 0;
    flex-basis: 33.333%;
    max-width: 33.333%;
  }
}

.person__image {
  display: block;
  border-radius: 50%;
  overflow: hidden;
  width: 100%;
}

.person__content {
  text-align: center;
  @media (--small-up) {
    text-align: left;
    flex-basis: 66.666%;
    max-width: 66.666%;
    padding-left: 1.5rem;
  }
}

.person__name {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.person__description {
  font-family: var(--font-georgia);
  line-height: 1.625;
  font-style: italic;
  margin-bottom: 0.5rem;
}

.person__contact {
  margin-bottom: 0.25rem;
}
